import React from "react";
import {
  Heading,
  Body,
  Hairline,
  Stack,
  Subheading,
  TextStyle,
} from "components";
import { formatPrice } from "utilities";

import translations from "./translations/en.json";

interface OverviewSectionProps {
  totalNumberOfProducts: number;
  subtotal: number;
}

const OverviewSection = ({
  totalNumberOfProducts,
  subtotal,
}: OverviewSectionProps) => {
  return (
    <Stack vertical>
      <Heading size="extraExtraSmall">{translations.cartSummary}</Heading>
      <Body size="small">
        {totalNumberOfProducts === 1
          ? `${totalNumberOfProducts} ${translations.itemInCart.singular}`
          : `${totalNumberOfProducts} ${translations.itemInCart.plural}`}
      </Body>
      <Hairline />
      <Stack distribution="equalSpacing">
        <Subheading size="extraSmall" textTransform="uppercase">
          <TextStyle variation="dark">{translations.subtotal}:</TextStyle>
        </Subheading>
        <Heading size="extraSmall">
          <TextStyle variation="weak">{formatPrice(subtotal)}</TextStyle>
        </Heading>
      </Stack>
    </Stack>
  );
};

export default OverviewSection;
