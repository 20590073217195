import React, { useState } from 'react';
import { classNames } from 'utilities';
import * as styles from './Image.module.scss';

interface ImageProps {
  fallback: JSX.Element;
  source: string;
  alt: string;
}

const Image = ({ fallback, source, alt }: ImageProps) => {
  const [shouldShowFallback, setShouldShowFallback] = useState(false);
  const onErrorCallback = () => {
    setShouldShowFallback(true);
  };

  const imageClassNames = classNames(
    styles.Image,
    shouldShowFallback && styles.Hidden
  );

  if (shouldShowFallback) {
    return fallback;
  }

  return (
    <img
      className={imageClassNames}
      src={source}
      alt={alt}
      onError={onErrorCallback}
    />
  );
};

export default Image;
