import React, { useState } from 'react';
import {
  Body,
  Button,
  Hairline,
  Heading,
  Stack,
  Subheading,
  TextField,
  TextStyle,
} from 'components';
import { formatPrice } from 'utilities';

import translations from './translations/en.json';
interface DiscountSectionProps {
  applyDiscountCallback: (arg: string) => void;
  lastSubmittedCode: string;
  validDiscount: boolean;
  discountedAmount?: number | null;
  freeShipping?: boolean;
}

const DiscountSection = ({
  applyDiscountCallback,
  lastSubmittedCode,
  validDiscount = false,
  discountedAmount,
}: DiscountSectionProps) => {
  const [discountCode, setDiscountCode] = useState('');

  // const { discountedAmount, shippingIncluded } = discount;

  const DiscountError = () => {
    if (!lastSubmittedCode) {
      return null;
    }

    return (
      <Body>
        <TextStyle variation='critical'>
          ('{lastSubmittedCode}') {translations.isNotValid}
        </TextStyle>
      </Body>
    );
  };

  const DiscountChecked = () => {
    const formattedDiscountAmount =
      discountedAmount > 0 ? (
        `– ${formatPrice(discountedAmount)}`
      ) : (
        <TextStyle variation='uppercase'>{translations.freeShipping}</TextStyle>
      );

    return (
      <Stack distribution='equalSpacing'>
        <Subheading size='extraSmall' textTransform='uppercase'>
          <TextStyle variation='dark'>
            {translations.discount} ('{lastSubmittedCode}'):
          </TextStyle>
        </Subheading>
        <Heading size='extraSmall'>
          <TextStyle variation='weak'>{formattedDiscountAmount}</TextStyle>
        </Heading>
      </Stack>
    );
  };

  const checkDiscountCode = () => {
    if (discountCode) {
      applyDiscountCallback(discountCode);
    }
  };

  return (
    <Stack vertical>
      <Stack distribution='equalSpacing'>
        <Stack.Item fill>
          <TextField
            value={discountCode}
            placeholder={translations.placeholder}
            onChange={setDiscountCode}
          />
        </Stack.Item>
        <Button primary onClick={checkDiscountCode} type='button'>
          {translations.applyCTA}
        </Button>
      </Stack>
      {!validDiscount && <DiscountError />}
      <Hairline />
      {validDiscount && <DiscountChecked />}
    </Stack>
  );
};

export default DiscountSection;
