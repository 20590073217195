import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { GlobalProvider, OverlayProvider, Router } from "foundation";

import * as styles from "./App.module.scss";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <GlobalProvider>
          <OverlayProvider>
            <div className={styles.App}>
              <Router />
            </div>
          </OverlayProvider>
        </GlobalProvider>
      </BrowserRouter>
    );
  }
}

export default App;
