import React from "react";
// import {Link} from 'react-router-dom';
import { classNames, appendClassName } from "utilities";
import { Link } from "components";

import * as styles from "./TextLink.module.scss";

interface TextLinkBaseProps {
  secondary?: boolean;
  variation?: "default" | "link" | "critical" | "light";
  withUnderline?: boolean;
  external?: boolean;
  beforeRedirect?: () => void;
}

interface ActionProps extends TextLinkBaseProps {
  onClick: () => void;
  to?: null;
  searchParams?: null;
  hash?: null;
}

interface LinkProps extends TextLinkBaseProps {
  onClick?: null;
  to: string;
  searchParams?: string;
  hash?: string;
}

type TextLinkProps = ActionProps | LinkProps;

const TextLink: React.FunctionComponent<TextLinkProps> = ({
  secondary,
  children,
  variation = "default",
  withUnderline,
  onClick,
  to,
  searchParams,
  hash,
  external,
  beforeRedirect = () => {},
}) => {
  const textLinkClassNames = classNames(
    styles.TextLink,
    secondary && styles.Secondary,
    styles[appendClassName("variation", variation)],
    withUnderline && styles.WithUnderline
  );

  if (external) {
    return (
      <a className={textLinkClassNames} href={to} target="_blank">
        {children}
      </a>
    );
  }

  if (onClick) {
    return (
      <button className={textLinkClassNames} onClick={onClick}>
        {children}
      </button>
    );
  }

  return (
    <Link
      to={to}
      searchParams={searchParams}
      hash={hash}
      beforeRedirect={beforeRedirect}
      inline
    >
      <div className={textLinkClassNames}>{children}</div>
    </Link>
  );
};

export default TextLink;
