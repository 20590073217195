import React from 'react';
import { CartProvider, SearchProvider } from '../../context';
import { ConnectionProvider } from '../ConnectionProvider';

export const GlobalProvider: React.FunctionComponent = ({ children }) => {
  return (
    <ConnectionProvider>
      <CartProvider>{children}</CartProvider>
    </ConnectionProvider>
  );
};
