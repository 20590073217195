import React from "react";
import { CategoryEnum } from "sections/Catalogues/types";
import { Heading, Link, Stack, TextLink } from "components";
import { CatalogueImage } from "../CatalogueImage";

import * as styles from "./Catalogue.module.scss";
import { capitalizeFirstLetter } from "utilities";

interface Catalogue {
  year: number;
  season: string;
  fileUrl: string;
  imageUrl: string;
}

interface PublisherCatalogues {
  title: string;
  catalogues: Catalogue[];
}

interface CatalogueProps {
  category: CategoryEnum;
  publisherCatalogues: PublisherCatalogues;
  withTitle?: boolean;
  withLinks?: boolean;
}

const Catalogue: React.FunctionComponent<CatalogueProps> = ({
  category,
  publisherCatalogues: { title, catalogues },
  withTitle = false,
  withLinks = false,
}) => {
  const sortedCatalogues = sortCatalogues(catalogues);
  const { year, season, fileUrl, imageUrl } = sortedCatalogues[0];
  return (
    <div className={styles.Catalogue}>
      <Stack spacing="baseTight" vertical>
        <Link to={constructPdfUrl(category, year, season, fileUrl)} external>
          <CatalogueImage
            imageUrl={constructImageUrl(category, year, season, imageUrl)}
            alt={`${title} catalogue`}
          />
        </Link>

        {(withTitle || withLinks) && (
          <Stack spacing="tight" vertical>
            {withTitle && <Heading size="extraSmall">{title}</Heading>}
            {withLinks &&
              catalogues.map((catalogue) => {
                const { year, season, fileUrl } = catalogue;
                return (
                  <TextLink
                    to={constructPdfUrl(category, year, season, fileUrl)}
                    external
                    secondary
                    withUnderline
                  >{`${capitalizeFirstLetter(
                    season
                  )} ${year} Catalogue`}</TextLink>
                );
              })}
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default Catalogue;

const BASE_S3_URL = "https://crosscan.s3.us-east-2.amazonaws.com/catalogues";

const constructImageUrl = (category, year, season, imageUrl) => {
  return `${BASE_S3_URL}/images/${category}/${year}/${season}/${imageUrl}`;
};

const constructPdfUrl = (category, year, season, fileUrl) => {
  if (fileUrl.startsWith("https")) {
    return fileUrl;
  }
  return `${BASE_S3_URL}/pdfs/${category}/${year}/${season}/${fileUrl}`;
};

const seasonsOrderDesc = ["fall", "spring"];

function orderCataloguesByReleaseDesc(a: Catalogue, b: Catalogue) {
  if (a.year > b.year) {
    return -1;
  } else if (a.year < b.year) {
    return 1;
  } else {
    if (
      seasonsOrderDesc.indexOf(a.season) < seasonsOrderDesc.indexOf(b.season)
    ) {
      return -1;
    } else {
      return 1;
    }
  }
}

const sortCatalogues = (catalogues: Catalogue[]) => {
  return catalogues.sort(orderCataloguesByReleaseDesc);
};
