import React from 'react';
import { appendClassName, classNames } from 'utilities';

import * as styles from './Icon.module.scss';

interface IconProps {
  source: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  size?: 'default' | 'large' | 'extraLarge';
  fill?:
    | 'base'
    | 'subdued'
    | 'light'
    | 'brand'
    | 'critical'
    | 'warning'
    | 'success'
    | 'link';
}

const Icon = ({ fill, size, source }: IconProps) => {
  const iconClassNames = classNames(
    styles.Icon,
    styles[appendClassName('fill', fill)],
    styles[appendClassName('size', size)]
  );

  const SourceComponent = source;

  return (
    <span className={iconClassNames}>
      {/* {source} */}
      <SourceComponent />
      {/* <SourceComponent
        className={styles.svg}
        focusable='false'
        aria-hidden='true'
      /> */}
      {/* To do: fallback external svg */}
      {/* <img src={source.toString()} alt='' aria-hidden='true' /> */}
    </span>
  );
};

export default Icon;
