import React from "react";
import { Body, Heading, Stack, TextLink, TextStyle } from "components";

import translations from "./translations/en.json";

import * as styles from "./HelpCard.module.scss";

const HelpCard = () => {
  return (
    <div className={styles.HelpCard}>
      <Stack vertical spacing="tight">
        <Heading size="extraSmall">{translations.needAssistance}</Heading>

        <Body size="small">
          {translations.contactUs}{" "}
          <TextLink to={`tel:${translations.phoneNumber}`} secondary external>
            <TextStyle variation="strong">{translations.phoneNumber}</TextStyle>
          </TextLink>
        </Body>
      </Stack>
    </div>
  );
};

export default HelpCard;
