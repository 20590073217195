import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const discountedPrice = 'discountedPrice';

const ProductQuery = gql`
  {
   products {
    id
    isbn
    title
    price
    author
    handle
    genre
    language
    curriculums
    inventory
    curriculums
    format
    ${discountedPrice}
   }
  }
`;

export default () => (
  <Query query={ProductQuery}>
    {({ data, loading }) => (
      <div>
        {loading
          ? 'loading...'
          : data.products.map(({ id, isbn, title, price, discountedPrice }) => (
              <div key={id}>
                <b>Title: {title}</b>
                <b>Isbn: {isbn}</b>
                <b>Discounted Price: {discountedPrice}</b>
                <p>Price: {price}</p>
              </div>
            ))}
      </div>
    )}
  </Query>
);
