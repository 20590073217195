import React from 'react';
import { Icon, Image } from 'components';
import { Book as BookIcon } from 'assets/icons';
import * as styles from './ProductImage.module.scss';

export interface ProductImageProps {
  imageUrl: string;
}

const ProductImage = ({ imageUrl }: ProductImageProps) => {
  const ProductImageFallback = (
    <div className={styles.ProductImageFallbackWrapper}>
      <div className={styles.ProductImageFallback}>
        <Icon source={BookIcon} fill='light' size='extraLarge' />
      </div>
    </div>
  );

  return (
    <div className={styles.ProductImage}>
      <Image
        source={imageUrl}
        alt='book cover'
        fallback={ProductImageFallback}
      />
    </div>
  );
};

export default ProductImage;
