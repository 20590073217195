import React from "react";
import {
  Body,
  DangerouslySetInnerHTML,
  Heading,
  Stack,
  Subheading,
  TextLink,
  TextStyle,
} from "components";
import * as styles from "./OverviewDetails.module.scss";
import translations from "./translations/en.json";
import { mapSnakeCaseToTitleCase } from "utilities";

export interface OverviewDetailsProps {
  title: string;
  subtitle?: string;
  series?: string;
  author?: string;
  publisher?: string;
  description: string;
  imageUrl?: string;
}

const OverviewDetails = ({
  author,
  description,
  publisher,
  title,
  subtitle,
  series,
}: OverviewDetailsProps) => {
  const TitleSection = (
    <Stack vertical spacing="extraTight">
      <Stack vertical spacing="extraExtraTight">
        <Heading size="extraLarge">
          <TextStyle variation="brand">
            <DangerouslySetInnerHTML>{title}</DangerouslySetInnerHTML>
          </TextStyle>
        </Heading>
        {subtitle && (
          <Heading>
            <TextStyle variation="darker">
              <DangerouslySetInnerHTML>{subtitle}</DangerouslySetInnerHTML>
            </TextStyle>
          </Heading>
        )}
      </Stack>
      {series && (
        <Heading size="extraSmall">
          <TextStyle variation="darkest">{series}</TextStyle>
        </Heading>
      )}
    </Stack>
  );

  const AuthorSection = (
    <Stack vertical spacing="extraTight">
      <Subheading size="extraSmall" textTransform="uppercase">
        {translations.author}
      </Subheading>
      {/* <TextLink to='/'> */}
      <Heading size="extraSmall">
        <TextStyle variation="strong">
          <DangerouslySetInnerHTML>
            {author ? author : "N/A"}
          </DangerouslySetInnerHTML>
        </TextStyle>
      </Heading>
      {/* </TextLink> */}
    </Stack>
  );

  const PublisherSection = publisher ? (
    <Stack vertical spacing="extraTight">
      <Subheading size="extraSmall" textTransform="uppercase">
        {translations.publisher}
      </Subheading>
      {/* <TextLink to='/'> */}
      <Heading size="extraSmall">
        <TextStyle variation="strong">
          {mapSnakeCaseToTitleCase(publisher)}
        </TextStyle>
      </Heading>
      {/* </TextLink> */}
    </Stack>
  ) : null;

  const DescriptionSection = (
    <Body>
      <DangerouslySetInnerHTML>{description}</DangerouslySetInnerHTML>
    </Body>
  );

  return (
    <div className={styles.OverviewDetails}>
      <Stack vertical spacing="baseLoose">
        {TitleSection}
        {AuthorSection}
        {PublisherSection}
        {DescriptionSection}
      </Stack>
    </div>
  );
};

export default OverviewDetails;
