import React from 'react';
import { Icon, Image } from 'components';
import { Book as BookIcon } from 'assets/icons';
import * as styles from './ProductImage.module.scss';

export interface ProductImageProps {
  imageUrl: string;
  alt?: string;
}

const ProductImage = ({ imageUrl, alt = '' }: ProductImageProps) => {
  const ProductImageFallback = (
    <div className={styles.ProductImageFallback}>
      <Icon source={BookIcon} fill='light' size='extraLarge' />
    </div>
  );

  return (
    <div className={styles.ProductImage}>
      <Image source={imageUrl} alt={alt} fallback={ProductImageFallback} />
    </div>
  );
};

export default ProductImage;
