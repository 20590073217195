import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCart } from 'hooks';
import { Routes } from 'foundation';

import { InformationSection, TitleSection } from './components';
import { useCreateOrder } from './hooks/useCreateOrder';
import { useCheckoutForm } from './hooks/useCheckoutForm';
import { TransactionSection } from '../TransactionSection';
import * as styles from './Checkout.module.scss';

interface CheckoutProps {}

const Checkout = ({}: CheckoutProps) => {
  const [createOrder, { data, loading, error }] = useCreateOrder();
  const {
    cart,
    clearCart,
    totalNumberOfProducts,
    discount: { value: discountApplied },
  } = useCart();
  const [formState, dispatch] = useCheckoutForm();
  const history = useHistory();

  useEffect(() => {
    if (data) {
      const {
        addOrder: { errors, order },
      } = data;

      if (errors?.length > 0) {
        return;
      } else {
        clearCart();
        history.push(Routes.orderReceived, order);
      }
    }
  }, [data]);

  const createOrderCallback = useCallback(
    (e) => {
      e.preventDefault();
      if (formState.errors.length > 0) {
        return;
      }
      createOrder({
        variables: {
          products: cart,
          shippingAddress: {
            address: formState.address,
            address2: formState.address2,
            city: formState.city,
            province: formState.province,
            postalCode: formState.postalCode,
            country: 'Canada',
            contact: formState.email,
            deliveryInstructions: formState.deliveryInstructions,
          },
          customerProfile: {
            firstName: formState.firstName,
            lastName: formState.lastName,
            phoneNumber: formState.phoneNumber,
            contactEmail: formState.email,
            institutionName: formState.institutionName,
            subscribed: formState.subscribed,
            title: formState.title,
          },
          discount: discountApplied,
        },
      });
    },
    [createOrder, formState]
  );

  return (
    <div className={styles.CheckoutWrapper}>
      {/* https://stephencharlesweiss.com/input-validation-preventdefault-onsubmit-vs-onclick/ */}
      <form
        className={styles.Checkout}
        id='checkoutForm'
        onSubmit={createOrderCallback}
      >
        <TitleSection />
        <InformationSection formState={formState} dispatch={dispatch} />
        <TransactionSection
          cart={cart}
          totalNumberOfProducts={totalNumberOfProducts}
          checkout
        />
      </form>
    </div>
  );
};

export default Checkout;
