import React from "react";
import {
  Body,
  Stack,
  Stepper,
  Subheading,
  TextLink,
  TextStyle,
} from "components";
import { Routes } from "foundation";
import { ProductFormatEnum } from "types";
import { mapToFormat } from "utilities";
import { useCart, useIsMediumScreen } from "hooks";

import { ProductPrice } from "../ProductPrice";

import translation from "./translations/en.json";

import * as styles from "./ProductDetails.module.scss";

interface ProductProps {
  author: string | null;
  format: string | null;
  isbn: string | null;
  publicationDate: string | null;
  subtitle: string | null;
  title: string | null;
  ean: string;
  price: number;
}
interface ProductDetailsProps {
  product: ProductProps;
  quantity: number;
}

const ProductDetails = ({ product, quantity }: ProductDetailsProps) => {
  const { author, format, isbn, publicationDate, subtitle, title, ean, price } =
    product;
  const isMediumScreen = useIsMediumScreen();

  const productLink = `${Routes.products}/${ean}`;

  const formatPublicationDate = (() => {
    if (!format && !publicationDate) {
      return null;
    }

    if (format && !publicationDate) {
      return <Body>{mapToFormat(format as ProductFormatEnum)}</Body>;
    }

    if (!format && publicationDate) {
      return <Body>{publicationDate}</Body>;
    }

    return (
      <Body>{`${mapToFormat(
        format as ProductFormatEnum
      )}, ${publicationDate}`}</Body>
    );
  })();

  const { updateCartItems } = useCart();
  const updateQuantity = (newQuantity) => {
    console.log({
      productId: ean,
      quantity: newQuantity - quantity,
    });
    updateCartItems([
      {
        productId: ean,
        quantity: newQuantity - quantity,
      },
    ]);
  };

  const removeFromCart = () => {
    updateCartItems([
      {
        productId: ean,
        quantity: -1 * quantity,
      },
    ]);
  };

  return (
    <Stack vertical>
      <Stack vertical spacing="extraExtraTight">
        <TextLink to={productLink} secondary>
          <Subheading>{title}</Subheading>
        </TextLink>
        {subtitle && <Body>{subtitle}</Body>}
      </Stack>
      <Stack vertical spacing="extraTight">
        <Body>
          <TextStyle variation="strong">{author}</TextStyle>
        </Body>
        {/* <TextLink to={Routes.searchBase}>{author}</TextLink> */}
        {formatPublicationDate && formatPublicationDate}
        <Body>ISBN: {ean}</Body>
      </Stack>
      {isMediumScreen ? <ProductPrice price={price} /> : null}
      <Stepper
        value={quantity}
        min={0}
        max={999}
        updateValue={updateQuantity}
      />
      <TextLink onClick={removeFromCart} secondary withUnderline>
        {translation.removeFromCart}
      </TextLink>
    </Stack>
  );
};

export default ProductDetails;
