import React from 'react';
import { Caption, TextStyle } from 'components';

import translations from './translations/en.json';

const Copyright = () => {
  return (
    <Caption>
      <TextStyle variation='light'>{translations.copyright}</TextStyle>
    </Caption>
  );
};

export default Copyright;
