import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { Body, Heading, Link, Stack, TextLink, TextStyle } from "components";
import { Routes } from "foundation";
import { CategoryEnum } from "sections/Catalogues/types";
import cataloguesData from "assets/catalogues/catalogues.json";

import Catalogue from "../Catalogue/Catalogue";
import translations from "./translations/en.json";

import * as styles from "./CataloguesByCategory.module.scss";

interface CataloguesByCategoryProps {}

const CataloguesByCategory: React.FunctionComponent<
  CataloguesByCategoryProps
> = () => {
  const { category } = useParams();
  if (!category || !Object.values(CategoryEnum).includes(category)) {
    return <Redirect to={Routes.home} />;
  }
  const catalogues = Object.entries(cataloguesData[category as CategoryEnum]);

  return (
    <div className={styles.CataloguesByCategoryWrapper}>
      <Stack vertical spacing="extraExtraLoose">
        {/* to add padding */}
        <div className={styles.CatalogueOverview}>
          <Stack vertical spacing="loose">
            <Heading size="large">{translations.title[category]}</Heading>
            <div>
              <Body>{translations.description.lineOne}</Body>
              <Body>{translations.description.lineTwo}</Body>
            </div>
          </Stack>
        </div>

        <div className={styles.CataloguesByCategory}>
          {catalogues.map(([key, publisherCatalogues]) => {
            // console.log(category, year, season, fileUrl, imageUrl);
            return (
              <Catalogue
                category={category}
                publisherCatalogues={publisherCatalogues}
                key={key}
                withTitle
                withLinks
              />
            );
          })}
        </div>
      </Stack>
    </div>
  );
};

export default CataloguesByCategory;
