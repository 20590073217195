export function formatPublicationDate(publicationDate: string | null) {
  if (publicationDate === '?' || !publicationDate) {
    return null;
  }

  const parsedYear = parseInt(
    publicationDate.substring(publicationDate.length - 2)
  );
  const endingYear = parsedYear < 10 ? '0' + parsedYear : parsedYear.toString();

  // TODO: figure out a better way of parsing. we could use date to dynamically fix this, but it's pretty inefficient nor a real solution
  if (isNaN(parsedYear) || endingYear.length <= 1) {
    return null;
  } else {
    if (parseInt(endingYear) < 22) {
      return `20${endingYear}`;
    } else {
      return `19${endingYear}`;
    }
  }
}
