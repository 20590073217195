import React from 'react';
import { Hairline } from 'components';

import { ProductMeta, ProductMetaProps, TitleSection } from './components';
import translations from './translations/en.json';
import * as styles from './DetailsSection.module.scss';

const DetailsSection = ({ metaData }: ProductMetaProps) => {
  return (
    <div className={styles.DetailsSection}>
      <div className={styles.HairlineWrapper}>
        <Hairline />
      </div>
      <TitleSection title={translations.details} />
      <ProductMeta metaData={metaData} />
      {/* <TitleSection title={translations.moreTitles} /> */}
    </div>
  );
};

export default DetailsSection;
