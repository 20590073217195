import React from "react";
import { Body, Stack, Heading, TextLink, TextStyle } from "components";
import { WhiteCrossCanLogo } from "assets/logos";

import translations from "./translations/en.json";

import * as styles from "./ContactInfo.module.scss";

const ContactInfo = () => {
  return (
    <div className={styles.ContactInfo}>
      <Stack vertical spacing="baseLoose">
        <span className={styles.Logo}>
          <WhiteCrossCanLogo />
        </span>
        <Stack vertical spacing="tight">
          <Heading size="extraSmall">
            <TextStyle variation="light">{translations.contactUs}</TextStyle>
          </Heading>
          <Stack vertical spacing="none">
            <Body>
              <TextStyle variation="light">
                {translations.addressLine1}
              </TextStyle>
            </Body>
            <Body>
              <TextStyle variation="light">
                {translations.addressLine2}
              </TextStyle>
            </Body>
          </Stack>
          <TextLink
            to={`tel:${translations.phoneNumber}`}
            external
            withUnderline
            variation="light"
          >
            {translations.phoneNumber}
          </TextLink>
          <TextLink
            to={`mailto:${translations.email}`}
            external
            withUnderline
            variation="light"
          >
            {translations.email}
          </TextLink>
        </Stack>
      </Stack>
    </div>
  );
};

export default ContactInfo;
