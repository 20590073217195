import translations from './translations/en.json';

interface FormatOptions {
  currency?: 'CAD' | 'USD';
  hideCurrency?: boolean;
}

export function formatPrice(
  price: number | null,
  options: FormatOptions = { currency: 'CAD', hideCurrency: false }
) {
  if (price !== 0 && !price) {
    return 'N/A';
  }

  const formattedPrice = `$${price.toFixed(2)}`;

  const { currency, hideCurrency } = options;

  if (hideCurrency) {
    return formattedPrice;
  }

  return `${translations[currency]} ${formattedPrice}`;
}
