import React from 'react';
import { SearchProvider } from 'context';
import { SearchContent } from './SearchContent';

export const Search = () => {
  return (
    <SearchProvider>
      <SearchContent />
    </SearchProvider>
  );
};
