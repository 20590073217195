import React from "react";
import { Icon, Image } from "components";
import { Book as BookIcon } from "assets/icons";
import * as styles from "./CatalogueImage.module.scss";

export interface CatalogueImageProps {
  imageUrl: string;
  alt?: string;
}

const CatalogueImage = ({ imageUrl, alt = "" }: CatalogueImageProps) => {
  const CatalogueImageFallback = (
    <div className={styles.CatalogueImageFallback}>
      <Icon source={BookIcon} fill="light" size="extraLarge" />
    </div>
  );

  return (
    <div className={styles.CatalogueImage}>
      <Image source={imageUrl} alt={alt} fallback={CatalogueImageFallback} />
    </div>
  );
};

export default CatalogueImage;
