import React from "react";
import { Body, Heading, Stack, TextLink, TextStyle } from "components";

import * as styles from "./SearchEmptyState.module.scss";
import translations from "./translations/en.json";
import { useSearch } from "hooks";

const SearchEmptyState = () => {
  const { searchTerm } = useSearch();
  return (
    <div className={styles.SearchEmptyState}>
      <Stack vertical spacing="loose">
        <Heading>
          {translations.noResultsFoundFor} {searchTerm}
        </Heading>

        <Stack vertical spacing="tight">
          <Heading size="extraSmall">{translations.searchTips.title}</Heading>
          <Stack vertical spacing="none">
            {translations.searchTips.tips.map((searchTip) => (
              <Body key={searchTip}>{searchTip}</Body>
            ))}
          </Stack>
        </Stack>

        <Stack vertical spacing="tight">
          <Heading size="extraSmall">{translations.needMoreHelp}</Heading>
          <TextLink to={`mailto:${translations.email}`} external>
            <TextStyle variation="strong">{translations.contactUs}</TextStyle>
          </TextLink>
        </Stack>
      </Stack>
    </div>
  );
};

export default SearchEmptyState;
