import React from 'react';
import { classNames } from 'utilities';
import { Caption } from 'components';
import * as styles from './Badge.module.scss';

interface BadgeProps {
  status?: 'default' | 'warning' | 'error' | 'branded' | 'sale';
}

const Badge: React.FunctionComponent<BadgeProps> = ({ status, children }) => {
  return (
    <div className={classNames(styles.Badge, statusClass(status))}>
      <Caption>{children}</Caption>
    </div>
  );
};

function statusClass(status = 'default') {
  switch (status) {
    case 'default':
    default:
      return '';
    case 'warning':
      return styles.Warning;
    case 'error':
      return styles.Error;
    case 'branded':
      return styles.Branded;
    case 'sale':
      return styles.Sale;
  }
}

export default Badge;
