import React from 'react';
import { Stack } from 'components';

import { ContactInfo, Copyright, QuickLinks, SocialMedia } from './components';
import * as styles from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.FooterBackgroundWrapper}>
      <div className={styles.Footer}>
        <div className={styles.FooterStackWrapper}>
          <Stack vertical spacing='extraLoose'>
            <Stack distribution='equalSpacing'>
              <ContactInfo />
              <QuickLinks />
            </Stack>
            <Stack distribution='equalSpacing'>
              <Copyright />
              <SocialMedia />
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Footer;
