import React from 'react';
import { classNames } from 'utilities';
import * as styles from './Button.module.scss';

interface ButtonProps {
  primary?: boolean;
  plain?: boolean;
  disabled?: boolean;
  square?: boolean;
  fullWidth?: boolean;
  onClick: () => void;
  attached?: {
    right?: boolean;
    left?: boolean;
  };
  form?: string;
  type?: 'button' | 'submit' | 'reset';
  label?: string;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  disabled,
  children,
  onClick,
  primary,
  square,
  fullWidth = false,
  plain,
  attached: { right: attachedRight, left: attachedLeft } = {
    right: false,
    left: false,
  },
  form,
  type,
  label = '',
}) => {
  return (
    <button
      className={classNames(
        styles.Button,
        primary && styles.Primary,
        plain && styles.Plain,
        fullWidth && styles.FullWidth,
        square && styles.Square,
        attachedRight && styles.AttachedRight,
        attachedLeft && styles.AttachedLeft
      )}
      onClick={!form ? onClick : null}
      disabled={disabled}
      form={form}
      type={type}
      aria-label={label}
    >
      {children}
    </button>
  );
};

export default Button;
