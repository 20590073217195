import React from "react";
import { appendClassName, classNames } from "utilities";
import * as styles from "./StackItem.module.scss";

export type SpacingType =
  | "none"
  | "extraExtraTight"
  | "extraTight"
  | "tight"
  | "baseTight"
  | "base"
  | "baseLoose"
  | "loose"
  | "extraLoose"
  | "extraExtraLoose";

interface StackItemProps {
  spacing?: SpacingType;
  fill?: boolean;
  fullWidth?: boolean;
}

const StackItem: React.FunctionComponent<StackItemProps> = ({
  children,
  spacing = "extraTight",
  fill = false,
  fullWidth = false,
}) => {
  const stackItemClassNames = classNames(
    styles.StackItem,
    styles[appendClassName("spacing", spacing)],
    fill && styles.ItemFill,
    fullWidth && styles.FullWidth
  );
  return <div className={stackItemClassNames}>{children}</div>;
};

export default StackItem;
