import React, { useState } from "react";
import {
  Body,
  Button,
  Heading,
  Link,
  Modal,
  Stack,
  Subheading,
  TextLink,
  TextStyle,
} from "components";
import { ProductType } from "hooks/useSearch";
import { formatPrice, mapToFormat } from "utilities";
import { Routes } from "foundation";

import { ProductImage, ProductVariantsModalBody } from "./components";
import translations from "./translations/en.json";

import * as styles from "./SearchResultProduct.module.scss";
interface SearchResultProductProps {
  product?: ProductType;
}

const SearchResultProduct = ({ product }: SearchResultProductProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const {
    author,
    ean,
    format,
    imageUrl,
    // language,
    price,
    publicationDate,
    // publisherName,
    // readingLevel,
    numberOfOtherVariants,
    subtitle,
    title,
  } = product;

  const formatPublicationDate = (() => {
    if (!format && !publicationDate) {
      return null;
    }

    if (format && !publicationDate) {
      return <Body>{mapToFormat(format)}</Body>;
    }

    if (!format && publicationDate) {
      return <Body>{publicationDate}</Body>;
    }

    return <Body>{`${mapToFormat(format)}, ${publicationDate}`}</Body>;
  })();

  const productLink = `${Routes.products}/${ean}`;

  return (
    <div className={styles.SearchResultProduct}>
      <Link to={productLink}>
        <ProductImage imageUrl={imageUrl} alt={`${title} book cover`} />
      </Link>
      <Stack vertical spacing="baseTight">
        <Stack vertical spacing="extraExtraTight">
          <TextLink to={productLink} secondary>
            <Subheading>{title}</Subheading>
          </TextLink>
          {subtitle && <Body>{subtitle}</Body>}
        </Stack>
        <Stack vertical spacing="extraExtraTight">
          {/* <TextLink to={Routes.searchBase}>{author}</TextLink> */}
          <Body>
            <TextStyle variation="strong">{author}</TextStyle>
          </Body>
          {formatPublicationDate && formatPublicationDate}
        </Stack>
        <Heading size="small">
          {formatPrice(price, { hideCurrency: true })}
        </Heading>
        {/* inventory out of stock warning */}
        <Stack vertical spacing="extraTight">
          <Button
            fullWidth
            onClick={() => {
              setModalOpen(true);
            }}
          >
            {translations.addToCart}
          </Button>
          {numberOfOtherVariants > 0 && (
            <TextLink
              secondary
              withUnderline
              onClick={() => {
                setModalOpen(true);
              }}
            >
              {translations.availableIn} {numberOfOtherVariants}{" "}
              {numberOfOtherVariants === 1
                ? translations.otherFormats.singular
                : translations.otherFormats.plural}
            </TextLink>
          )}
        </Stack>
      </Stack>
      <Modal open={modalOpen} onModalClose={closeModal}>
        <ProductVariantsModalBody productEan={ean} onModalClose={closeModal} />
      </Modal>
    </div>
  );
};

export default SearchResultProduct;
