import React, { useCallback, useEffect, useState } from "react";
import { Button, Stack, TextField } from "components";

import { usePriceRangeState } from "./hooks/usePriceRangeState";
import { SearchFilterSection } from "../SearchFilterSection";
import translations from "./translations/en.json";

import * as styles from "./PriceFilter.module.scss";

interface PriceFilterProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const PriceFilter: React.FunctionComponent<PriceFilterProps> = ({
  children,
  isOpen,
  toggleIsOpen,
}) => {
  const {
    min: minFromParam,
    max: maxFromParam,
    setPriceCallback,
    removePriceParam,
  } = usePriceRangeState();

  const [min, setMin] = useState<number | string>(minFromParam);
  const [max, setMax] = useState<number | string>(maxFromParam);

  const refMin = !min ? min : min < 0 && 0;
  const refMax = !max ? max : max < 0 && 0;

  const formatNumber = (value) => {
    const refValue = parseInt(value as string) || "";
    if (typeof refValue == "number") {
      return Math.max(value, 0);
    }
    return "";
  };

  const setMinCallback = (value) => {
    setMin(formatNumber(value));
  };
  const setMaxCallback = (value) => {
    setMax(formatNumber(value));
  };

  useEffect(() => {
    setMinCallback(minFromParam);
    setMaxCallback(maxFromParam);
  }, [minFromParam, maxFromParam]);

  const applyFilterCallback = useCallback(() => {
    if (!min && !max) {
      removePriceParam();
    } else if (!min || !max) {
      setPriceCallback(min, max);
    } else if (min > max) {
      setMin(max);
      setMax(min);
      setPriceCallback(max, min);
    } else {
      setPriceCallback(min, max);
    }
  }, [min, max]);

  return (
    <SearchFilterSection
      title={translations.title}
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
    >
      <Stack vertical>
        <Stack alignment="center">
          <Stack.Item fill>
            <TextField
              value={min}
              onChange={setMinCallback}
              type="number"
              placeholder={translations.min}
              min={0}
            />
          </Stack.Item>
          <span>–</span>
          <Stack.Item fill>
            <TextField
              value={max}
              onChange={setMaxCallback}
              type="number"
              placeholder={translations.max}
              min={0}
            />
          </Stack.Item>
        </Stack>
        <Button fullWidth onClick={applyFilterCallback}>
          {translations.apply}
        </Button>
      </Stack>
    </SearchFilterSection>
  );
};

export default PriceFilter;
