import React from 'react';
import * as styles from './SocialMediaBar.module.scss';

interface SocialMediaBarProps {

};

const SocialMediaBar: React.FunctionComponent<SocialMediaBarProps> = ({
  children
}) => {
  return (
    <div className={styles.SocialMediaBar}>
      {children}
    </div>
  );
}

export default SocialMediaBar;