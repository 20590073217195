import { gql } from '@apollo/client';

export const PRODUCT_DETAILS_FOR_CSV = gql`
  query productDetailsForCSV($ean: [String!]!) {
    productsByEanArray(ean: $ean) {
      ean
      format
      price
      publisherName
      title
    }
  }
`;
