import React from 'react';
import { classNames } from 'utilities';

import * as styles from './Collapsible.module.scss';

interface CollapsibleProps {
  open: boolean;
}

const Collapsible: React.FunctionComponent<CollapsibleProps> = ({
  children,
  open,
}) => {
  const collapsibleClassNames = classNames(
    styles.Collapsible,
    open && styles.Open
  );
  return <div className={collapsibleClassNames}>{children}</div>;
};

export default Collapsible;
