import React, { useEffect } from "react";
import { useModalContext } from "context";
import { StopEventPropagation } from "components";

import * as styles from "./Modal.module.scss";

import { Body, Header, Footer } from "./components";

interface ModalProps {
  open: boolean;
  onModalClose?: () => void;
}

interface ModalComponent extends React.FunctionComponent<ModalProps> {
  Body: typeof Body;
  Header: typeof Header;
  Footer: typeof Footer;
}

const Modal: ModalComponent = ({ children, open, onModalClose }) => {
  const { openModal, closeModal } = useModalContext();

  const modalContent = (
    <div className={styles.Overlay} onClick={onModalClose}>
      <div className={styles.ModalGrid}>
        <div className={styles.ModalWrapper}>
          <StopEventPropagation>
            <div className={styles.Modal}>{children}</div>
          </StopEventPropagation>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    open ? openModal(modalContent) : closeModal();
  }, [open]);

  return null;
};

Modal.Header = Header;
Modal.Footer = Footer;
Modal.Body = Body;

export default Modal;
