import React from 'react';
import { Body, Heading, Stack, Subheading, TextStyle } from 'components';

import translations from './translations/en.json';
import * as styles from './MetaCell.module.scss';

interface MetaCellProps {
  title: string;
  primary?: boolean;
}

const MetaCell: React.FunctionComponent<MetaCellProps> = ({
  title,
  primary = false,
  children,
}) => {
  const ContentWrapper = primary ? Heading : Body;

  let content = children ? children : translations.notAvailable;

  if (Array.isArray(children)) {
    if (children.length === 0) {
      content = translations.notAvailable;
    }
  }

  return (
    <div className={styles.MetaCell}>
      <Stack vertical spacing='extraTight'>
        <Subheading size='extraSmall' textTransform='uppercase'>
          <TextStyle variation='dark'>{title}</TextStyle>
        </Subheading>
        <ContentWrapper>{content}</ContentWrapper>
      </Stack>
    </div>
  );
};

export default MetaCell;
