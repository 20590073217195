import React from 'react';

import {
  Breadcrumbs,
  OverviewSection,
  OverviewSectionProps,
  TransactionSection,
  TransactionSectionProps,
} from './components';
import * as styles from './TopSection.module.scss';

interface TopSectionProps
  extends OverviewSectionProps,
    TransactionSectionProps {}

const TopSection = ({
  overviewDetails,
  transactionDetails,
}: TopSectionProps) => {
  return (
    <div className={styles.TopSection}>
      <Breadcrumbs />
      <OverviewSection overviewDetails={overviewDetails} />
      <TransactionSection transactionDetails={transactionDetails} />
      {/* <OverviewSection />
      <TransactionSection /> */}
    </div>
  );
};

export default TopSection;
