import { gql } from '@apollo/client';

export const IS_VALID_DISCOUNT_CODE = gql`
  query isValidDiscountCode($discountCode: String!) {
    discountVerification(discountCode: $discountCode) {
      validDiscount
      discount
      shippingIncluded
    }
  }
`;
