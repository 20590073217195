import React from "react";
import { Button } from "components";
import * as styles from "./Footer.module.scss";

export interface ActionProps {
  content: string;
  onClick: () => void;
}

interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = ({ children }) => {
  return <div className={styles.Footer}>{children}</div>;
};

export default Footer;
