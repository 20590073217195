import { useCallback } from 'react';
import { useToastContext } from 'context';

export const useToast = () => {
  const { addToast: addToastContext } = useToastContext();

  const addToast = useCallback(
    (content, options = {}) => {
      addToastContext(content, options);
    },
    [addToastContext]
  );

  return {
    addToast,
  };
};
