import React, { createContext, useContext } from 'react';

interface State {
  bannerIds: string[];
  activeBannerId: string | null;
}

const initialState: State = {
  bannerIds: [],
  activeBannerId: null,
};

const BannerContext = createContext<State>(initialState);

export const BannerProvider: React.FunctionComponent = ({ children }) => {
  return (
    <BannerContext.Provider value={initialState}>
      {children}
    </BannerContext.Provider>
  );
};

export const useBannerContext = () => useContext(BannerContext);
