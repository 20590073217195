import { useReducer } from 'react';

export type CheckoutFormState = {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  title: string | null;
  institutionName: string | null;
  phoneNumber: string | null;
  address: string | null;
  address2: string | null;
  city: string | null;
  province: string | null;
  postalCode: string | null;
  deliveryInstructions: string | null;
  subscribed: boolean;
  errors: CheckoutFormActions[];
};
//could derive state with typeOf but this allows for null

const initialState: CheckoutFormState = {
  email: '',
  firstName: '',
  lastName: '',
  title: '',
  institutionName: '',
  phoneNumber: '',
  address: '',
  address2: '',
  city: '',
  province: '',
  postalCode: '',
  deliveryInstructions: '',
  subscribed: false,
  errors: [],
};

export enum TitleTypes {
  Librarian = 'Librarian',
  LibraryTech = 'Library Tech',
  Teacer = 'Teacher',
  Principal = 'Principal',
  LiteracySpecialist = 'Literacy specialist',
  CurriculumCoordinator = 'Curriculum co-ordinator',
  TeacherLibrarian = 'Teacher Librarian',
  Other = 'Other',
}

export enum CheckoutFormActions {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  title = 'title',
  institutionName = 'institutionName',
  phoneNumber = 'phoneNumber',
  address = 'address',
  address2 = 'address2',
  city = 'city',
  province = 'province',
  postalCode = 'postalCode',
  deliveryInstructions = 'deliveryInstructions',
  subscribed = 'subscribed',
  errors = 'errors',
}

export type DispatchAction = {
  type: CheckoutFormActions;
  payload: string | CheckoutFormActions[] | boolean | null;
};

function reducer(state, action: DispatchAction) {
  const { type, payload } = action;

  return {
    ...state,
    [type]: payload,
  };
}

export function useCheckoutForm() {
  return useReducer(reducer, initialState);
}
