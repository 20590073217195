import React from 'react';
import * as styles from './SocialMedia.module.scss';

interface SocialMediaProps {

};

const SocialMedia: React.FunctionComponent<SocialMediaProps> = ({
  children
}) => {
  return (
    <div className={styles.SocialMedia}>
      {children}
    </div>
  );
}

export default SocialMedia;