import { useQuery } from "@apollo/client";
import { ProductCollectionEnum, ProductFormatEnum } from "types";
import { formatPublicationDate } from "utilities";

import { SEARCH_ALL_PRODUCTS } from "./graphql/searchAllProducts";
import { ProductOrderBy } from "types";

interface UseSearchProductsProps {
  searchTerm: string;
  first?: number;
  skip?: number;
  priceMax?: number;
  priceMin?: number;
  format?: ProductFormatEnum[];
  collection?: ProductCollectionEnum[];
  orderBy?: ProductOrderBy;
}

export const useSearchProducts = ({
  searchTerm,
  first,
  skip: offset,
  priceMax,
  priceMin,
  format,
  collection,
  orderBy,
}: UseSearchProductsProps) => {
  const filter =
    searchTerm === "_" && collection.length > 0
      ? {}
      : {
          filter: {
            titleContains: searchTerm,
            OR: {
              subtitleContains: searchTerm,
              OR: {
                isbnContains: searchTerm,
                OR: {
                  eanContains: searchTerm,
                },
              },
            },
          },
        };

  const args = {
    ...filter,
    first: first,
    skip: offset,
    // yells at empty arrays
    ...(format.length > 0 ? { format } : {}),
    ...(collection.length > 0 ? { collection } : {}),
    ...(priceMax && { priceMax }),
    ...(priceMin && { priceMin }),
    orderBy,
  };

  const { data, loading, error, fetchMore } = useQuery(SEARCH_ALL_PRODUCTS, {
    variables: args,
    fetchPolicy: "cache-and-network",
  });

  const products = data?.productSearch?.products
    ? data.productSearch.products.map((product) => {
        return {
          ...product,
          publicationDate: formatPublicationDate(product.publicationDate),
        };
      })
    : [];

  return {
    products: products,
    totalCount: data?.productSearch.totalCount ?? 0,
    loading,
    error,
    fetchMore,
  };
};
