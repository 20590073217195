import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Body, Button, Modal, Stack, TextStyle } from "components";
import { useCart, useToast } from "hooks";

import { useProductVariantsByEan } from "./hooks";
import { VariantListItem } from "./components";

import translations from "./translations/en.json";

interface ProductVariantsModalBodyProps {
  productEan: string;
  onModalClose: () => void;
}

const ProductVariantsModalBody: React.FunctionComponent<
  ProductVariantsModalBodyProps
> = ({ productEan, onModalClose }) => {
  // in the future there needs to be a refactor so that we fire queries after opening the modal (and not before)
  // there is a not obvious tradeoff for managing state, so if you close the modal your values would persist in this case
  // recreting that would take more effort
  const reducer = (state, action) => {
    switch (action.type) {
      case "update":
        const { ean, quantity } = action.payload;
        const indexOfVariant = state.findIndex(
          (product) => product.ean === ean
        );
        state[indexOfVariant].quantity = quantity;
        return [...state];
      case "variantsFetched":
        return action.payload;
      default:
        return state;
    }
  };
  const [productSelectedAmountState, dispatch] = useReducer(reducer, []);
  const { products, loading, error } = useProductVariantsByEan(productEan);
  const [itemsSelectedCount, setItemsSelectedCount] = useState(0);
  const { updateCartItems } = useCart();

  const { addToast } = useToast();
  const toastContent = (
    <Body>
      <TextStyle variation="light">
        {itemsSelectedCount === 1
          ? `${itemsSelectedCount} ${translations.itemsAddedToCart.singular}`
          : `${itemsSelectedCount} ${translations.itemsAddedToCart.plural}`}
      </TextStyle>
    </Body>
  );

  useEffect(() => {
    if (!loading) {
      dispatch({
        type: "variantsFetched",
        payload: products.map((product) => {
          return { ean: product.ean, quantity: 0 };
        }),
      });
    }
  }, [loading]);

  const getItemsCount = (itemState) =>
    itemState.reduce(
      (prevValue, currentValue) => prevValue + currentValue.quantity,
      0
    );

  useEffect(() => {
    setItemsSelectedCount(getItemsCount(productSelectedAmountState));
  }, [productSelectedAmountState]);

  const updateProductCallback = useCallback(
    (ean, quantity) => {
      dispatch({ type: "update", payload: { ean, quantity } });
    },
    [dispatch]
  );

  const updateCart = () => {
    onModalClose();
    const itemsToUpdate = productSelectedAmountState.map((variant) => {
      const { ean: productId, quantity } = variant;
      return {
        productId,
        quantity,
      };
    });
    updateCartItems(itemsToUpdate);
    addToast(toastContent);
  };

  if (loading || error) {
    return null;
  }

  const Header = () => {
    return (
      <Modal.Header title={translations.title} closeModal={onModalClose} />
    );
  };

  const Footer = () => (
    <Modal.Footer>
      <Stack alignment="center">
        <Stack.Item fill>
          <Body size="small">
            {itemsSelectedCount === 1
              ? `${itemsSelectedCount} ${translations.itemsSelected.singular}`
              : `${itemsSelectedCount} ${translations.itemsSelected.plural}`}
          </Body>
        </Stack.Item>
        <Stack>
          <Button plain onClick={onModalClose} label={translations.cancel}>
            {translations.cancel}
          </Button>
          <Button
            primary
            onClick={updateCart}
            label={translations.addToCart}
            disabled={itemsSelectedCount === 0}
          >
            {translations.addToCart}
          </Button>
        </Stack>
      </Stack>
    </Modal.Footer>
  );

  return (
    <>
      <Header />
      <Modal.Body>
        <Stack vertical spacing="loose">
          {products.map((product) => (
            <VariantListItem
              product={product}
              onChange={updateProductCallback}
              beforeRedirect={onModalClose}
              key={`${product.ean}-modal`}
            />
          ))}
        </Stack>
      </Modal.Body>
      <Footer />
    </>
  );
};

export default ProductVariantsModalBody;
