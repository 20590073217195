import { gql } from '@apollo/client';

export const PRODUCT_DETAILS = gql`
  query productDetails($ean: String!) {
    productVariantsByEan(ean: $ean) {
      author
      collectionId
      collectionNames
      copyrightYear
      curriculums
      discountedPrice
      ean
      format
      genre
      handle
      imageUrl
      imprintName
      inventory
      isbn
      itemCode
      language
      longDescription
      numberOfIllustrations
      numberOfPages
      onSaleDate
      price
      publicationDate
      publisherName
      readingLevel
      subtitle
      title
    }
  }
`;
