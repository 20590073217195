import { useQuery } from '@apollo/client';
import { ProductFormatEnum } from 'types';
import { formatPublicationDate, mapToProvince } from 'utilities';

import { CART_TOTAL } from './graphql/cartTotal';

import { CartProductType } from 'context';

interface UseCartTotalProps {
  products: CartProductType[];
  province?: string | null;
  discountCode?: string;
}

export const useCartTotal = ({
  products,
  province = null,
  discountCode,
}: UseCartTotalProps) => {
  const formattedArgs = {
    products,
    province: mapToProvince(province),
    discountCode,
  };

  const { data, loading, error } = useQuery(CART_TOTAL, {
    variables: formattedArgs,
  });

  if (data && !loading) {
    const cartTotals = data.cartTotal;

    return {
      cartTotals,
      loading,
      error,
    };
  }

  return {
    cartTotals: null,
    loading,
    error,
  };
};
