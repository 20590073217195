import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ComponentPage from "ComponentPage";
import { Header, Footer, ScrollToTop } from "components";
import { Cart, Catalogues, Home, Products, Search } from "sections";

import { Routes, VanityRedirects } from "./Routes";

export const Router = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route path={Routes.cart} render={() => <Cart />} />
        <Route exact path={Routes.home} render={() => <Home />} />
        <Route
          path={Routes.searchBase}
          exact
          render={() => <Redirect to={`${Routes.searchBase}/_`} />}
        />
        <Route path={Routes.products} render={() => <Products />} />
        <Route path={Routes.search} render={() => <Search />} />
        <Route
          path={Routes.catalogue}
          exact
          render={() => <Redirect to={Routes.home} />}
        />
        <Route path={Routes.catalogue} render={() => <Catalogues />} />
        {/* <Route path={Routes.componentPage} render={() => <ComponentPage />} /> */}
        <Route
          path={VanityRedirects.ontarioCurriculum.url}
          exact
          render={() => (
            <Redirect to={VanityRedirects.ontarioCurriculum.redirect} />
          )}
        />
        <Route
          path={VanityRedirects.princeGeorgeVirtualFair.url}
          exact
          render={() => (
            <Redirect to={VanityRedirects.princeGeorgeVirtualFair.redirect} />
          )}
        />
        <Route
          path={Routes.catchAll}
          render={() => <Redirect to={Routes.home} />}
        />
      </Switch>
      <Footer />
    </>
  );
};
