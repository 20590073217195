import React from "react";
import { Body, Button, Heading, Stack } from "components";

import * as styles from "./DivisionsSection.module.scss";

import translations from "./translations/en.json";
import { ClassroomLogo, DigitalLogo, LibraryLogo } from "./assets/logos";

const DivisionsSection: React.FunctionComponent = () => {
  const buttonRedirectCallback = (url: string) => {
    return () => window.open(url);
  };

  return (
    <div className={styles.DivisionsSectionWrapper}>
      <div className={styles.DivisionsSection}>
        <div className={styles.DivisionsSectionTitle}>
          <Heading size="large">{translations.divisionsSection.title}</Heading>
        </div>
        <div className={styles.Division}>
          <Stack vertical spacing="loose">
            <div className={styles.LogoWrapper}>
              <LibraryLogo />
            </div>
            <Body>{translations.divisionsSection.descriptions.library}</Body>
          </Stack>
        </div>
        <div className={styles.Division}>
          <Stack vertical spacing="loose">
            <div className={styles.LogoWrapper}>
              <ClassroomLogo />
            </div>
            <Body>{translations.divisionsSection.descriptions.classroom}</Body>
            <Button
              primary
              onClick={buttonRedirectCallback(
                "http://www.crosscaneducation.com/classroom.php"
              )}
            >
              {translations.divisionsSection.learnMoreCTA}
            </Button>
          </Stack>
        </div>
        <div className={styles.Division}>
          <Stack vertical spacing="loose">
            <div className={styles.LogoWrapper}>
              <DigitalLogo />
            </div>
            <Body>{translations.divisionsSection.descriptions.digital}</Body>
            <Button
              primary
              onClick={buttonRedirectCallback(
                "http://www.crosscaneducation.com/digital.php"
              )}
            >
              {translations.divisionsSection.learnMoreCTA}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default DivisionsSection;
