import React from "react";
import { capitalizeFirstLetter, classNames } from "utilities";

import * as styles from "./Spinner.module.scss";

interface SpinnerProps {
  size?: "small" | "medium" | "large";
}

const Spinner = ({ size = "medium" }: SpinnerProps) => {
  return (
    <div className={styles.Spinner}>
      <div
        className={classNames(styles.Disc, styles[capitalizeFirstLetter(size)])}
      />
    </div>
  );
};

export default Spinner;
