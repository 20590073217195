import React from 'react';
import { classNames } from 'utilities';
import * as styles from './Banner.module.scss';

interface BannerProps {
  critical?: boolean;
}

const Banner: React.FunctionComponent<BannerProps> = ({
  children,
  critical,
}) => {
  const bannerClassNames = classNames(
    styles.Banner,
    critical && styles.Critical
  );

  return <div className={bannerClassNames}>{children}</div>;
};

export default Banner;
