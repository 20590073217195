import React from "react";
import { ProductCollectionEnum } from "types";
import { mapToCollection } from "utilities";
import { Checkbox, Stack } from "components";

import { SearchFilterSection } from "../SearchFilterSection";
import { useCollectionState } from "./hooks/useCollectionState";
import translations from "./translations/en.json";

import * as styles from "./CollectionFilter.module.scss";

interface CollectionFilterProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const CollectionFilter = ({ isOpen, toggleIsOpen }: CollectionFilterProps) => {
  const productCollectionEnumKeys = Object.keys(ProductCollectionEnum);
  const { filteredCollections, filteredCollectionsCallback } =
    useCollectionState();

  const filterOptions = filteredCollections.map((value, index) => {
    return {
      label: mapToCollection(
        ProductCollectionEnum[productCollectionEnumKeys[index]]
      ),
      selected: value,
      toggleChecked: () =>
        filteredCollectionsCallback(
          ProductCollectionEnum[productCollectionEnumKeys[index]]
        ),
    };
  });

  return (
    <SearchFilterSection
      title={translations.title}
      // options={filterOptions}
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
    >
      <Stack vertical spacing="extraTight">
        {filterOptions.map(({ label, selected, toggleChecked }) => {
          return (
            <Checkbox
              label={label}
              checked={selected}
              toggleChecked={toggleChecked}
              key={label}
            />
          );
        })}
      </Stack>
    </SearchFilterSection>
  );
};

export default CollectionFilter;
