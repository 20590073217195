import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "foundation";
import { Button, Icon, Link, Stack, TextField } from "components";
import { useIsMediumScreen, useSearchUrl } from "hooks";
import {
  ShoppingCart as ShoppingCartIcon,
  MobileMenu as MobileMenuIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from "assets/icons";

import { Logo, SmallLogo } from "./logo";
import { HeaderMenu, MobileSearch, SubHeader } from "./components";
import * as styles from "./Header.module.scss";
import translations from "./translations/en.json";

const Header = () => {
  const [searchValue, setSearchValue] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenuOpen = () => {
    setMobileMenuOpen((mobileMenuOpen) => !mobileMenuOpen);
  };

  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const toggleMobileSearchOpen = () => {
    setMobileSearchOpen((mobileSearchOpen) => !mobileSearchOpen);
  };

  const closeOpenHeaders = () => {
    setMobileMenuOpen(false);
    setMobileSearchOpen(false);
  };

  const isMediumScreen = useIsMediumScreen();
  const search = useSearchUrl();
  let history = useHistory();

  const redirectToCart = () => history.push(Routes.cart);

  const searchAction = {
    onClick: () => search(searchValue),
    primary: true,
    content: translations.search,
  };

  const headerContent = !isMediumScreen ? (
    <Stack alignment="center" spacing="extraLoose">
      <Stack.Item spacing="extraLoose" fill>
        <TextField
          value={searchValue}
          onChange={setSearchValue}
          placeholder={translations.placeholder}
          action={searchAction}
          type="search"
        />
      </Stack.Item>
      <HeaderMenu />
    </Stack>
  ) : (
    <>
      {!mobileMenuOpen && !mobileSearchOpen ? (
        <Stack alignment="center" distribution="trailing" spacing="baseTight">
          <Button plain square onClick={toggleMobileSearchOpen}>
            <Icon source={SearchIcon} />
          </Button>
          <Button plain square onClick={redirectToCart}>
            <Icon source={ShoppingCartIcon} />
          </Button>
          <Button plain square onClick={toggleMobileMenuOpen}>
            <Icon source={MobileMenuIcon} />
          </Button>
        </Stack>
      ) : (
        <Button plain square onClick={closeOpenHeaders}>
          <Icon source={CloseIcon} />
        </Button>
      )}
    </>
  );

  return (
    <div className={styles.HeaderWrapperWithBorder}>
      <div className={styles.HeaderWrapper}>
        <div className={styles.Header}>
          <Stack
            alignment="trailing"
            distribution="equalSpacing"
            spacing="extraLoose"
          >
            <Link to={Routes.home}>
              {isMediumScreen ? <SmallLogo /> : <Logo />}
            </Link>
            <Stack.Item spacing="extraLoose" fill={!isMediumScreen}>
              {headerContent}
            </Stack.Item>
          </Stack>
          <SubHeader mobileMenuOpen={mobileMenuOpen} />
          {isMediumScreen && (
            <MobileSearch
              isOpen={mobileSearchOpen}
              toggleIsOpen={toggleMobileSearchOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
