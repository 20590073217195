import React, { useState } from "react";

import * as styles from "./SearchFilters.module.scss";
import { CollectionFilter, FormatFilter, PriceFilter } from "./components";
import { useIsMediumScreen } from "hooks";

// interface SearchFiltersProps {}

const SearchFilters = () => {
  const isMediumScreen = useIsMediumScreen();
  const [formatIsOpen, setFormatIsOpen] = useState(
    isMediumScreen ? false : true
  );
  const toggleFormatIsOpen = () => setFormatIsOpen((isOpen) => !isOpen);
  const [collectionIsOpen, setCollectionIsOpen] = useState(
    isMediumScreen ? false : true
  );
  const toggleCollectionIsOpen = () => setCollectionIsOpen((isOpen) => !isOpen);
  const [priceIsOpen, setPriceIsOpen] = useState(isMediumScreen ? false : true);
  const togglePriceIsOpen = () => setPriceIsOpen((isOpen) => !isOpen);

  return (
    <div className={styles.SearchFilters}>
      <CollectionFilter
        isOpen={collectionIsOpen}
        toggleIsOpen={toggleCollectionIsOpen}
      />
      <FormatFilter isOpen={formatIsOpen} toggleIsOpen={toggleFormatIsOpen} />
      <PriceFilter isOpen={priceIsOpen} toggleIsOpen={togglePriceIsOpen} />
    </div>
  );
};

export default SearchFilters;
