import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Stack,
  Heading,
  Hairline,
  Body,
  TextLink,
  TextStyle,
} from "components";
import { Routes } from "foundation";

import * as styles from "./OrderReceived.module.scss";

import translations from "./translations/en.json";

const OrderReceived = () => {
  const { state: order } = useLocation();

  const history = useHistory();

  if (!order) {
    history.push(Routes.home);
    return null;
  }

  return (
    <div className={styles.OrderReceivedWrapper}>
      <div className={styles.OrderReceived}>
        <div className={styles.Content}>
          <Stack vertical>
            <Heading>{translations.displayMessage}</Heading>
            <Hairline />
            <Stack vertical spacing="loose">
              <Stack vertical>
                <Heading size="extraSmall">
                  {translations.confirmation.orderNumber}
                  {order.id}
                </Heading>
                <Body>
                  {translations.confirmation.sentTo}
                  {order.customer}
                </Body>
                <TextLink to={Routes.home}>
                  <TextStyle variation="strong">
                    {translations.confirmation.continueShopping}
                  </TextStyle>
                </TextLink>
              </Stack>
              <Stack vertical>
                <Heading size="extraSmall">{translations.help.title}</Heading>
                <TextLink to={`mailto:${translations.help.email}`} external>
                  <TextStyle variation="strong">
                    {translations.help.contactUs}
                  </TextStyle>
                </TextLink>
              </Stack>
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default OrderReceived;
