const StaticRoutes = {
  home: "/",
  products: "/products",
  catalogue: "/catalogue",
  componentPage: "/components",
  searchBase: "/search",
  cart: "/cart",
  checkout: "/cart/checkout",
  orderReceived: "/cart/orderReceived",
  catchAll: "*",
};

const DynamicRoutes = {
  catalogueByCategory: `${StaticRoutes.catalogue}/:category`,
  productById: `${StaticRoutes.products}/:id`,
  search: `${StaticRoutes.searchBase}/:search`,
};

export const VanityRedirects = {
  ontarioCurriculum: {
    url: "/ontario-curriculum",
    redirect: `${StaticRoutes.searchBase}/_?collection=%5B"ontario_curriculum"%5D`,
  },
  princeGeorgeVirtualFair: {
    url: "/prince-george-virtual-fair",
    redirect: `${StaticRoutes.searchBase}/_?collection=%5B%22prince_george_virtual_fair%22%5D`,
  },
};

export const Routes = { ...StaticRoutes, ...DynamicRoutes };
