import React, { useState } from 'react';
import { Checkbox, Hairline, Heading, Stack, TextField } from 'components';

import {
  CheckoutFormActions,
  CheckoutFormState,
  DispatchAction,
} from '../../../../types';

import translations from './translations/en.json';

interface AccountSectionProps {
  formState: CheckoutFormState;
  dispatch: (args: DispatchAction) => void;
}

const AccountSection = ({ formState, dispatch }: AccountSectionProps) => {
  const [checked, setChecked] = useState(false);
  const toggleChecked = () => {
    dispatch({
      type: CheckoutFormActions.subscribed,
      payload: !checked,
    });
    setChecked((checked) => !checked);
  };

  const emailOnChangeCallback = (value) => {
    dispatch({
      type: CheckoutFormActions.email,
      payload: value,
    });
  };

  return (
    <Stack vertical spacing='baseLoose'>
      <Heading size='small'>{translations.title}</Heading>
      <Stack vertical spacing='extraTight'>
        <TextField
          value={formState.email}
          onChange={emailOnChangeCallback}
          placeholder={translations.emailPlaceholder}
          type='email'
        />
        <Checkbox
          label={translations.stayUpToDate}
          checked={checked}
          toggleChecked={toggleChecked}
        />
      </Stack>
      <Hairline spacing='none' />
    </Stack>
  );
};

export default AccountSection;
