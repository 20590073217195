import React from 'react';
import { Body, Subheading } from 'components';

interface LabelProps {
  labelFor: string;
  secondary?: boolean;
}

const Label: React.FunctionComponent<LabelProps> = ({
  children,
  labelFor,
  secondary = false,
}) => {
  const LabelContent = !secondary ? (
    <Subheading size='extraSmall' textTransform='uppercase'>
      {children}
    </Subheading>
  ) : (
    <Body size='small'>{children}</Body>
  );

  return <label htmlFor={labelFor}>{LabelContent}</label>;
};

export default Label;
