import { useQuery } from '@apollo/client';
import { PRODUCT_DETAILS_FOR_CSV } from './graphql/productDetailsForCSV';

export const useProductDetailsForCSV = (ean: string[]) => {
  const { data, loading, error } = useQuery(PRODUCT_DETAILS_FOR_CSV, {
    variables: { ean },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  if (data?.productsByEanArray) {
    return {
      products: data?.productsByEanArray,
      loading,
      error,
    };
  }

  return { products: null, loading, error };
};
