import React from 'react';
import { BannerProvider, ModalProvider, ToastProvider } from '../../context';

export const OverlayProvider: React.FunctionComponent = ({ children }) => {
  return (
    <BannerProvider>
      <ToastProvider>
        <ModalProvider>{children}</ModalProvider>
      </ToastProvider>
    </BannerProvider>
  );
};
