import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Routes } from 'foundation';

import { CartList, Checkout, OrderReceived } from './components';

export const Cart = () => {
  return (
    <Switch>
      <Route path={Routes.cart} exact>
        <CartList />
      </Route>
      <Route path={Routes.checkout}>
        <Checkout />
      </Route>
      <Route path={Routes.orderReceived}>
        <OrderReceived />
      </Route>
    </Switch>
  );
};
