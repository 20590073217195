import React, { createContext, useContext, useEffect, useState } from "react";

import { ProvinceEnum } from "types";
import { useLocalStorage } from "hooks";

// TO DO: get this type from graphql
export interface CartProductType {
  productId: string;
  quantity: number;
}

// to do: create user context
interface ProvinceType {
  value?: ProvinceEnum | null;
  setProvince: (args: string) => void;
}

interface DiscountType {
  value?: string | null;
  setDiscount: (args: string) => void;
}

interface State {
  cart: CartProductType[];
  updateCartItems: (args: CartProductType[]) => void;
  clearCart: () => void;
  totalNumberOfProducts: number;
  discount: DiscountType;
  province: ProvinceType;
}

const initialState: State = {
  cart: [],
  updateCartItems: (args) => {},
  clearCart: () => {},
  totalNumberOfProducts: 0,
  discount: {
    value: null,
    setDiscount: (args) => {},
  },
  province: {
    value: null,
    setProvince: (args) => {},
  },
};

const CartContext = createContext<State>(initialState);

const CROSSCAN_CART_KEY = "cc_ls_cart";

export const CartProvider: React.FunctionComponent = ({ children }) => {
  const [localStorageCart, setLocalStorageCart] = useLocalStorage(
    CROSSCAN_CART_KEY,
    []
  );
  const [cart, setCart] = useState(localStorageCart);
  const [totalNumberOfProducts, setTotalNumberOfProducts] = useState(0);
  const [appliedDiscount, setAppliedDiscount] = useState("");
  const [province, setProvince] = useState(null);

  const clearCart = () => {
    setCart([]);
    setLocalStorageCart([]);
  };

  const setDiscount = (discount) => {
    setAppliedDiscount(discount);
  };

  useEffect(() => {
    const totalProductCount = cart.reduce((previousValue, product) => {
      return previousValue + product.quantity;
    }, 0);

    setTotalNumberOfProducts(totalProductCount);
  }, [cart]);

  const updateCartItems = (itemsToUpdate: CartProductType[]) => {
    setCart((cart) => {
      let refCart = [...cart];

      itemsToUpdate.map((itemToUpdate) => {
        const { quantity, productId } = itemToUpdate;
        const indexOfItem = refCart.findIndex(
          (cartItem) => cartItem.productId === productId
        );

        //if the item is already in the cart
        if (indexOfItem > -1) {
          const { quantity: currentQuantity } = refCart[indexOfItem];

          const newQuantity = quantity + currentQuantity;

          // update the existing product if the new quantity is greater than 0
          if (newQuantity > 0) {
            refCart[indexOfItem] = {
              quantity: quantity + currentQuantity,
              productId,
            };

            const updatedCart = [...refCart];
            setLocalStorageCart(updatedCart);
            refCart = updatedCart;
            return;
          }

          // remove the item if the new quantity is less than 0
          refCart.splice(indexOfItem, 1);
          const updatedCart = [...refCart];
          setLocalStorageCart(updatedCart);
          refCart = updatedCart;
          return;
        }

        // if it doesn't already exist, append it
        const updatedCart = [...refCart, { quantity, productId }];
        setLocalStorageCart(updatedCart);
        refCart = updatedCart;
        return;
      });

      return refCart;
    });
  };

  // const updateCartItem = ({ quantity, productId }: CartProductType) => {
  //   setCart((cart) => {
  //     const indexOfItem = cart.findIndex(
  //       (cartItem) => cartItem.productId === productId
  //     );

  //     //if the item is already in the cart
  //     if (indexOfItem > -1) {
  //       const { quantity: currentQuantity } = cart[indexOfItem];

  //       const newQuantity = quantity + currentQuantity;

  //       // update the existing product if the new quantity is greater than 0
  //       if (newQuantity > 0) {
  //         cart[indexOfItem] = {
  //           quantity: quantity + currentQuantity,
  //           productId,
  //         };

  //         const updatedCart = [...cart];
  //         setLocalStorageCart(updatedCart);
  //         return updatedCart;
  //       }

  //       // remove the item if the new quantity is less than 0
  //       cart.splice(indexOfItem, 1);
  //       const updatedCart = [...cart];
  //       setLocalStorageCart(updatedCart);
  //       return updatedCart;
  //     }

  //     // if it doesn't already exist, append it
  //     const updatedCart = [...cart, { quantity, productId }];
  //     setLocalStorageCart(updatedCart);
  //     return updatedCart;
  //   });
  // };

  const value = {
    cart,
    updateCartItems,
    clearCart,
    totalNumberOfProducts,
    discount: {
      value: appliedDiscount,
      setDiscount,
    },
    province: {
      value: province,
      setProvince,
    },
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCartContext = () => useContext(CartContext);
