import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUpdateSearchParams } from "hooks";

const searchParamKey = "price";

export const usePriceRangeState = () => {
  const { search } = useLocation();
  const [min, setMin] = useState<number | string>("");
  const [max, setMax] = useState<number | string>("");

  const { updateSearchParams } = useUpdateSearchParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const json = function (raw) {
      try {
        return JSON.parse(raw);
      } catch (e) {
        return {};
      }
    };
    let priceParam = json(searchParams.get(searchParamKey));

    if (!priceParam) {
      return;
    }

    const { min, max } = priceParam;
    setMin(min);
    setMax(max);
  }, [search, setMin, setMax]);

  const setPriceCallback = (min, max) => {
    updateSearchParams({
      name: searchParamKey,
      value: {
        min: min,
        max: max,
      },
    });
  };

  const removePriceParam = () => {
    updateSearchParams({
      name: searchParamKey,
      value: "delete",
    });
  };

  return { min, max, setPriceCallback, removePriceParam };
};
