export function appendClassName(reference: string, addition: string) {
  const className =
    capitalizeFirstLetter(reference) + capitalizeFirstLetter(addition);

  return className;
}

export function capitalizeFirstLetter(string: string) {
  if (!string || !string.charAt(0)) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}
