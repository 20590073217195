import { useCallback } from "react";
import { Routes } from "foundation";
import { useHistory, useLocation } from "react-router-dom";

export const useSearchUrl = () => {
  let history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const search = useCallback(
    (searchTerm) => {
      history.push(
        `${Routes.searchBase}/${encodeURI(
          searchTerm
        )}?${searchParams.toString()}`
      );
    },
    [history, searchParams]
  );

  return search;
};
