import React from 'react';
import { Heading, TextStyle } from 'components';

import * as styles from './TitleSection.module.scss';

interface TitleSectionProps {
  title: string;
}

const TitleSection = ({ title }: TitleSectionProps) => {
  return (
    <div className={styles.TitleSection}>
      <Heading size='small'>
        <TextStyle variation='brand'>{title}</TextStyle>
      </Heading>
    </div>
  );
};

export default TitleSection;
