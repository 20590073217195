import React from 'react';

import {
  OverviewDetails,
  OverviewDetailsProps as OverviewDetailsBaseProps,
  ProductImage,
  ProductImageProps,
} from './components';
import * as styles from './OverviewSection.module.scss';

export type OverviewDetailsProps = OverviewDetailsBaseProps & ProductImageProps;

export interface OverviewSectionProps {
  overviewDetails: OverviewDetailsProps;
}

const OverviewSection = ({
  overviewDetails: { imageUrl, ...product },
}: OverviewSectionProps) => {
  return (
    <div className={styles.OverviewSection}>
      <ProductImage imageUrl={imageUrl} />
      <OverviewDetails {...product} />
    </div>
  );
};

export default OverviewSection;
