import React, { useEffect, useState } from "react";
import { Button, Heading, Icon, Stack } from "components";
import { Close as CloseIcon } from "assets/icons";
import { classNames } from "utilities";
import * as styles from "./Toast.module.scss";

interface ToastProps {
  critical?: boolean;
  success?: boolean;
  closeToast: () => void;
}

const Toast: React.FunctionComponent<ToastProps> = ({
  children,
  critical,
  success,
  closeToast,
}) => {
  return (
    <div
      className={classNames(
        styles.Toast,
        critical && styles.Critical,
        success && styles.Success
      )}
    >
      <Stack alignment="center" distribution="equalSpacing">
        <Stack.Item fill>{children}</Stack.Item>
        <Button plain onClick={closeToast} square>
          <Icon source={CloseIcon} fill="light" />
        </Button>
      </Stack>
    </div>
  );
};

export default Toast;
