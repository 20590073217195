import translations from './translations/en.json';

export enum ValidateTextFieldEnum {
  phoneNumber = 'phoneNumber',
  postalCode = 'postalCode',
}

const validate = {
  phoneNumber: (value: string): boolean | string => {
    // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
    const phoneRegEx = new RegExp(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i
    );
    return phoneRegEx.test(value) ? true : translations.error.phoneNumber;
  },
  postalCode: (value: string): boolean | string => {
    const postalCodeEx = new RegExp(
      /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/i
    );
    return postalCodeEx.test(value) ? true : translations.error.postalCode;
  },
};

export function validateTextField(type: ValidateTextFieldEnum) {
  return validate[type];
}
