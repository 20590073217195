import React, { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CartProductType, useCart, useCartTotal } from 'hooks';
import { Body, Button, Hairline, Heading, Stack, Subheading } from 'components';
import { mapToProvince } from 'utilities';

import { DiscountSection, OverviewSection, TotalSection } from './components';
import * as styles from './CartSummary.module.scss';

interface CartSummaryProps {
  cart: CartProductType[];
  totalNumberOfProducts: number;
  checkout?: boolean;
  callback: () => void;
}

const CartSummary = ({
  cart,
  totalNumberOfProducts,
  checkout = false,
  callback,
}: CartSummaryProps) => {
  const {
    discount: { value: appliedDiscount, setDiscount },
    province: { value: provinceValue },
  } = useCart();

  const checkDiscountCode = useCallback((discountCode: string) => {
    // isDiscountCodeValid({ variables: { discountCode } });
    setDiscount(discountCode);
    //to do, handle replacing discount only if valid
  }, []);

  const { cartTotals, loading, error } = useCartTotal({
    products: cart,
    province: provinceValue,
    discountCode: appliedDiscount,
  });

  if (loading) {
    return (
      <div className={styles.CartSummary}>
        <Body>Cart summary is loading...</Body>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.CartSummary}>
        <Body>Something went wrong, try refreshing the page</Body>
      </div>
    );
  }

  return (
    <div className={styles.CartSummary}>
      <Stack vertical>
        <OverviewSection
          subtotal={cartTotals.subtotal}
          totalNumberOfProducts={totalNumberOfProducts}
        />
        <DiscountSection
          applyDiscountCallback={checkDiscountCode}
          lastSubmittedCode={appliedDiscount}
          validDiscount={cartTotals.discount.validDiscount}
          discountedAmount={cartTotals.discount.discountedAmount}
        />
        <TotalSection
          total={cartTotals.total}
          tax={cartTotals.totalTax}
          shipping={cartTotals.shippingTotal}
          freeShipping={cartTotals.discount.shippingIncluded}
          checkout={checkout}
          callback={callback}
        />
      </Stack>
    </div>
  );
};

export default CartSummary;
