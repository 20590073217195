import React, { useCallback, useEffect } from "react";
import { useSearch } from "hooks";
import { Banner, Body, Button, Spinner } from "components";

import { ResultsOverviewSection, SearchResultProduct } from "./components";

import translations from "./translations/en.json";

import * as styles from "./SearchResults.module.scss";

interface SearchResultsProps {
  setHasResults: (arg: boolean) => void;
}

const SearchResults = ({ setHasResults }: SearchResultsProps) => {
  const {
    searchTerm,
    products,
    totalCount,
    loading,
    error,
    fetchMore,
    // filter: {
    //   format: { filteredFormats },
    // },
  } = useSearch();

  const showMoreCallback = useCallback(() => {
    console.log(products.length);
    fetchMore({
      variables: {
        skip: products.length,
      },
    });
  }, [products]);

  if (error) {
    return (
      <div className={styles.SearchResults}>
        <Banner critical>
          <Body>Something went wrong, please refresh the page.</Body>
        </Banner>
      </div>
    );
  }

  if (products.length == 0 && loading) {
    return (
      <div className={styles.SearchResults}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.SearchResults}>
      <ResultsOverviewSection
        searchTerm={searchTerm}
        currentCount={products.length}
        totalCount={totalCount}
      />
      <div className={styles.SearchResultProducts}>
        {products.map((product) => (
          <SearchResultProduct key={product.ean} product={product} />
        ))}
      </div>
      {products.length > 0 && products.length < totalCount && (
        <div className={styles.ShowMoreWrapper}>
          <Button onClick={showMoreCallback} primary>
            {translations.showMoreCTA}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
