import { useQuery } from "@apollo/client";
import { PRODUCT_VARIANTS_BY_EAN } from "./graphql/productVariantsByEan";

export const useProductVariantsByEan = (ean: string) => {
  const { data, loading, error } = useQuery(PRODUCT_VARIANTS_BY_EAN, {
    variables: { ean },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
  });

  const productsData = data?.productVariantsByEan || [];
  const products = [...productsData];

  if (products.length > 1) {
    // orginal ean should show first
    products.sort((a, _b) => (a.ean === ean ? -1 : 0));
  }

  return {
    products,
    loading,
    error,
  };
};
