import React from 'react';
import { Heading } from 'components';

import translations from './translations/en.json';

import * as styles from './TitleSection.module.scss';

const TitleSection = () => {
  return (
    <div className={styles.TitleSection}>
      <Heading size='large'>{translations.myCart}</Heading>
    </div>
  );
};

export default TitleSection;
