import React from 'react';
import { appendClassName, classNames } from 'utilities';

import * as styles from './Hairline.module.scss';

interface HairlineProps {
  spacing?: 'default' | 'none';
}

const Hairline = ({ spacing = 'default' }: HairlineProps) => {
  const hairlineClassNames = classNames(
    styles.Hairline,
    styles[appendClassName('spacing', spacing)]
  );

  return <hr className={hairlineClassNames} />;
};

export default Hairline;
