/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ProductCollectionEnum {
  elementary_vendor_fair = "elementary_vendor_fair",
  french_fiction_vendor_fair = "french_fiction_vendor_fair",
  french_non_fiction_vendor_fair = "french_non_fiction_vendor_fair",
  high_school_vendor_fair = "high_school_vendor_fair",
  juvenile_fiction_vendor_fair = "juvenile_fiction_vendor_fair",
  ontario_curriculum = "ontario_curriculum",
  prince_george_virtual_fair = "prince_george_virtual_fair",
}

export enum ProductFormatEnum {
  audio_cd = "audio_cd",
  big_book = "big_book",
  board_book = "board_book",
  database = "database",
  ebook = "ebook",
  hardcover = "hardcover",
  hardcover_jacket = "hardcover_jacket",
  hardcover_plus_cd = "hardcover_plus_cd",
  hardcover_plus_ebook = "hardcover_plus_ebook",
  hardcover_series = "hardcover_series",
  interactive_books = "interactive_books",
  paper_over_board = "paper_over_board",
  paperback = "paperback",
  paperback_6_back = "paperback_6_back",
  paperback_plus_cd = "paperback_plus_cd",
  paperback_set = "paperback_set",
  paperback_wf = "paperback_wf",
  prebound = "prebound",
  primary_source = "primary_source",
  setup_required = "setup_required",
  spiral = "spiral",
}

export enum ProductOrderBy {
  ean_ASC = "ean_ASC",
  price_ASC = "price_ASC",
  price_DESC = "price_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
}

export enum ProductPublisherEnum {
  FHW = "FHW",
  a_d_xtreme = "a_d_xtreme",
  a_ditions_michel_quintin = "a_ditions_michel_quintin",
  a_kids_book_about = "a_kids_book_about",
  a_kids_book_about_inc = "a_kids_book_about_inc",
  abc = "abc",
  abc_clio = "abc_clio",
  abdo = "abdo",
  abdo_daughters = "abdo_daughters",
  abdo_kids = "abdo_kids",
  abdo_kids_jumbo = "abdo_kids_jumbo",
  abdo_kids_junior = "abdo_kids_junior",
  abdo_publishing = "abdo_publishing",
  abdo_zoom = "abdo_zoom",
  abrams = "abrams",
  adams_media = "adams_media",
  aladdin = "aladdin",
  aladdin_press = "aladdin_press",
  algonquin_books = "algonquin_books",
  amazon_publishing = "amazon_publishing",
  american_psychological_association = "american_psychological_association",
  amicus = "amicus",
  amulet = "amulet",
  amulet_books = "amulet_books",
  andersen_press_usa = "andersen_press_usa",
  annick_press = "annick_press",
  antheneum = "antheneum",
  argosy_press = "argosy_press",
  arsenal_pulp_press = "arsenal_pulp_press",
  ascd = "ascd",
  atheneum_books = "atheneum_books",
  atheneum_books_for_young_readers = "atheneum_books_for_young_readers",
  atheneum_caitlyn_dlouhy_books = "atheneum_caitlyn_dlouhy_books",
  av2 = "av2",
  av2_by_weigl = "av2_by_weigl",
  ballantine = "ballantine",
  bantam = "bantam",
  basic_books = "basic_books",
  bayard = "bayard",
  beach_lane_books = "beach_lane_books",
  beacon_press = "beacon_press",
  bear = "bear",
  bearport = "bearport",
  bearport_publishing = "bearport_publishing",
  beech_street_books = "beech_street_books",
  beech_street_books_french = "beech_street_books_french",
  bellwether_media = "bellwether_media",
  big_buddy_books = "big_buddy_books",
  big_small = "big_small",
  bigfoot_books = "bigfoot_books",
  blink = "blink",
  bloomsbury_usa = "bloomsbury_usa",
  blossom_books = "blossom_books",
  blue_door_education = "blue_door_education",
  bookhug_press = "bookhug_press",
  bouton_d_or_acadie = "bouton_d_or_acadie",
  brilliance_audio = "brilliance_audio",
  britannica = "britannica",
  britannica_educational_publishing = "britannica_educational_publishing",
  bruns = "bruns",
  calico = "calico",
  calico_kid = "calico_kid",
  canadian_curriculum_press = "canadian_curriculum_press",
  candelwick_press = "candelwick_press",
  candlewick = "candlewick",
  candlewick_press = "candlewick_press",
  cantata_learning = "cantata_learning",
  canterbury_classics = "canterbury_classics",
  capstone = "capstone",
  capstone_publishing = "capstone_publishing",
  cassava_republic_press = "cassava_republic_press",
  catapult = "catapult",
  cavendish = "cavendish",
  cavendish_square = "cavendish_square",
  central_recovery_press_llc = "central_recovery_press_llc",
  chapter_books = "chapter_books",
  charlesbridge = "charlesbridge",
  checkerboard_library = "checkerboard_library",
  chelsea_house = "chelsea_house",
  cherry_lake = "cherry_lake",
  cherry_lake_publishing = "cherry_lake_publishing",
  child = "child",
  child_s_play = "child_s_play",
  chouette = "chouette",
  chouette_publishing = "chouette_publishing",
  chronicle_books = "chronicle_books",
  claw = "claw",
  cody_koala = "cody_koala",
  core_library = "core_library",
  cormorant_books = "cormorant_books",
  cormorant_books_inc = "cormorant_books_inc",
  corwin_press_inc = "corwin_press_inc",
  counterpoint = "counterpoint",
  crabtree = "crabtree",
  crabtree_publishing = "crabtree_publishing",
  crabtree_publishing_company = "crabtree_publishing_company",
  creston_books = "creston_books",
  dash = "dash",
  denene_millner_books_simon_schuster_books_for_young_readers = "denene_millner_books_simon_schuster_books_for_young_readers",
  dial = "dial",
  dial_books_for_young_readers = "dial_books_for_young_readers",
  discoverroo = "discoverroo",
  disney_hyperion = "disney_hyperion",
  dk = "dk",
  dk_games = "dk_games",
  dottir_press = "dottir_press",
  doubleday_canada = "doubleday_canada",
  douglas_and_mcintyre_2013_ltd = "douglas_and_mcintyre_2013_ltd",
  dundurn = "dundurn",
  dundurn_press = "dundurn_press",
  dutton_children_s_books = "dutton_children_s_books",
  ecw_press = "ecw_press",
  editions_michel_quintin = "editions_michel_quintin",
  enslow = "enslow",
  enslow_publishers_inc = "enslow_publishers_inc",
  enslow_publishing = "enslow_publishing",
  epic_press = "epic_press",
  essential_library = "essential_library",
  farrar_straus_and_giroux_byr = "farrar_straus_and_giroux_byr",
  farrar_strauss_giroux = "farrar_strauss_giroux",
  feiwel_friends = "feiwel_friends",
  fernwood_publishing = "fernwood_publishing",
  fifth_house_publishers = "fifth_house_publishers",
  fir = "fir",
  firefly = "firefly",
  firefly_books = "firefly_books",
  first_second = "first_second",
  fitzhenry_and_whiteside = "fitzhenry_and_whiteside",
  flamingo_rampant = "flamingo_rampant",
  flower = "flower",
  flowerpot_press = "flowerpot_press",
  fly = "fly",
  flyaway_books = "flyaway_books",
  formac = "formac",
  formac_publishing_company_limited = "formac_publishing_company_limited",
  frances_lincoln_children_s_books = "frances_lincoln_children_s_books",
  free_spirit_publishing = "free_spirit_publishing",
  full_tilt_press = "full_tilt_press",
  g_p_putnam = "g_p_putnam",
  g_p_putnam_s_sons_books_for_young_readers = "g_p_putnam_s_sons_books_for_young_readers",
  gallery = "gallery",
  gallery_books = "gallery_books",
  gallery_saga_press = "gallery_saga_press",
  gareth_stevens = "gareth_stevens",
  gareth_stevens_library = "gareth_stevens_library",
  gareth_stevens_publishing = "gareth_stevens_publishing",
  gareth_stevens_world_almanac = "gareth_stevens_world_almanac",
  gecko_press = "gecko_press",
  goodmind = "goodmind",
  grand_central_publishing = "grand_central_publishing",
  grand_river_foundation = "grand_river_foundation",
  graphic_novels = "graphic_novels",
  green = "green",
  greenhaven = "greenhaven",
  greenhaven_publishing = "greenhaven_publishing",
  greenwood = "greenwood",
  grey = "grey",
  greystone_books = "greystone_books",
  greystone_books_ltd = "greystone_books_ltd",
  groundwood_books = "groundwood_books",
  grove_atlantic = "grove_atlantic",
  guilford_publications = "guilford_publications",
  guinness_world_records = "guinness_world_records",
  hachette_children_s = "hachette_children_s",
  harbour_publishing_co_ltd = "harbour_publishing_co_ltd",
  harpercollins = "harpercollins",
  harpercollins_publishers = "harpercollins_publishers",
  harry_n_abrams = "harry_n_abrams",
  harvard_business_review_press = "harvard_business_review_press",
  harvard_education_pr = "harvard_education_pr",
  haymarket_books = "haymarket_books",
  hbg = "hbg",
  hcp = "hcp",
  henry_holt_and_co = "henry_holt_and_co",
  henry_holt_and_co_byr = "henry_holt_and_co_byr",
  hmh_books = "hmh_books",
  holiday_house = "holiday_house",
  holy_cow_press = "holy_cow_press",
  houghton_mifflin_harcourt = "houghton_mifflin_harcourt",
  house_of_anansi_press_inc = "house_of_anansi_press_inc",
  hungry_tomato_ltd = "hungry_tomato_ltd",
  independent_thinking_press = "independent_thinking_press",
  infobase = "infobase",
  inhabit_media = "inhabit_media",
  inkyard_press = "inkyard_press",
  insight = "insight",
  insight_editions = "insight_editions",
  iversen = "iversen",
  iversen_publishing = "iversen_publishing",
  ivy_kids = "ivy_kids",
  jackdaw_publications = "jackdaw_publications",
  james_lorimer_company_ltd_publishers = "james_lorimer_company_ltd_publishers",
  jessica_kingsley_publishers = "jessica_kingsley_publishers",
  jump = "jump",
  kaeden_publishing = "kaeden_publishing",
  kaleidoscope = "kaleidoscope",
  kane_press = "kane_press",
  kbp = "kbp",
  kcp = "kcp",
  kegedonce_press = "kegedonce_press",
  kidhaven_publishing = "kidhaven_publishing",
  kids_can_press = "kids_can_press",
  kids_core = "kids_core",
  kingfisher = "kingfisher",
  kingston_publishers_ltd = "kingston_publishers_ltd",
  knopf_canada = "knopf_canada",
  knopf_doubleday_publishing_group = "knopf_doubleday_publishing_group",
  knopf_random_vintage_canada = "knopf_random_vintage_canada",
  lane = "lane",
  lantana_publishing = "lantana_publishing",
  lbc = "lbc",
  lee_low = "lee_low",
  lerner = "lerner",
  lerner_publishing = "lerner_publishing",
  lerner_publishing_group = "lerner_publishing_group",
  lernerclassroom = "lernerclassroom",
  levine_querido_c_o_chronicle_books = "levine_querido_c_o_chronicle_books",
  libraries_unlimited = "libraries_unlimited",
  linwood_barclay = "linwood_barclay",
  little_bee_books = "little_bee_books",
  little_brown = "little_brown",
  little_brown_and_company = "little_brown_and_company",
  little_brown_books_for_young_readers = "little_brown_books_for_young_readers",
  live_oak_media = "live_oak_media",
  lorimer = "lorimer",
  lorimer_children_teens = "lorimer_children_teens",
  lpg = "lpg",
  lucent_books = "lucent_books",
  lucent_press = "lucent_press",
  mackin = "mackin",
  magic_wagon = "magic_wagon",
  mango = "mango",
  margaret_k_mcelderry_books = "margaret_k_mcelderry_books",
  marshall_cavendish = "marshall_cavendish",
  marvel = "marvel",
  marvel_press = "marvel_press",
  mason_crest_publishers = "mason_crest_publishers",
  maverick_arts = "maverick_arts",
  maverick_arts_publishing_ltd = "maverick_arts_publishing_ltd",
  mcclelland_stewart = "mcclelland_stewart",
  mcclelland_stewart_use_5030_random = "mcclelland_stewart_use_5030_random",
  mcgill_queen_s_university_press = "mcgill_queen_s_university_press",
  medicine_wheel_education = "medicine_wheel_education",
  merriam_webster = "merriam_webster",
  milkweed_editions = "milkweed_editions",
  mitchell_lane = "mitchell_lane",
  morgan = "morgan",
  morgan_reynolds_publishing = "morgan_reynolds_publishing",
  mouton_noir_acadie = "mouton_noir_acadie",
  mtv_books = "mtv_books",
  new_frontier_publishing = "new_frontier_publishing",
  new_harbinger_publications = "new_harbinger_publications",
  new_society_publishers = "new_society_publishers",
  new_york_times_educational_publishing = "new_york_times_educational_publishing",
  nightwood_editions = "nightwood_editions",
  nimbus = "nimbus",
  nimbus_publishing = "nimbus_publishing",
  nimbus_publishing_limited = "nimbus_publishing_limited",
  north = "north",
  north_star = "north_star",
  norwood = "norwood",
  norwood_house_press = "norwood_house_press",
  omni = "omni",
  omnigraphics = "omnigraphics",
  one_elm_books_red_chair_press = "one_elm_books_red_chair_press",
  orca = "orca",
  orca_book_publishers = "orca_book_publishers",
  orca_books = "orca_books",
  orca_currents = "orca_currents",
  orca_soundings = "orca_soundings",
  oup = "oup",
  owlkids_books = "owlkids_books",
  oxford_university_press = "oxford_university_press",
  pacific_northwest_pub_not_set = "pacific_northwest_pub_not_set",
  page_street_publishing = "page_street_publishing",
  page_two_books_inc = "page_two_books_inc",
  pajama = "pajama",
  pajama_press = "pajama_press",
  pajama_press_inc = "pajama_press_inc",
  pembroke_publishers = "pembroke_publishers",
  penguin = "penguin",
  penguin_canada = "penguin_canada",
  penguin_publishing_group = "penguin_publishing_group",
  penguin_random_house = "penguin_random_house",
  penguin_random_house_canada_teen = "penguin_random_house_canada_teen",
  penguin_young_readers_group = "penguin_young_readers_group",
  penw = "penw",
  penworthy = "penworthy",
  philomel = "philomel",
  picador = "picador",
  play = "play",
  pocket_books = "pocket_books",
  portage_main_press = "portage_main_press",
  potter_ten_speed_harmony_rodale = "potter_ten_speed_harmony_rodale",
  powerkids_press = "powerkids_press",
  praeger = "praeger",
  prh = "prh",
  prh_canada_young_readers = "prh_canada_young_readers",
  publicaffairs = "publicaffairs",
  puffin = "puffin",
  puffin_canada = "puffin_canada",
  purple_toad = "purple_toad",
  qeb_publishing_quarto_library = "qeb_publishing_quarto_library",
  quarto_publishing = "quarto_publishing",
  quarto_publishing_group_usa = "quarto_publishing_group_usa",
  quintin = "quintin",
  quirk_books = "quirk_books",
  rai = "rai",
  random_house = "random_house",
  random_house_children_s_books = "random_house_children_s_books",
  random_house_of_canada = "random_house_of_canada",
  random_house_of_canada_limited = "random_house_of_canada_limited",
  random_house_uk = "random_house_uk",
  red_chair_press = "red_chair_press",
  red_deer_press = "red_deer_press",
  road_runner_press = "road_runner_press",
  roaring_brook_press = "roaring_brook_press",
  rodale_books = "rodale_books",
  rosen = "rosen",
  rosen_central = "rosen_central",
  rosen_classroom = "rosen_classroom",
  rosen_digital = "rosen_digital",
  rosen_publishing = "rosen_publishing",
  rosen_ya = "rosen_ya",
  rosen_young_adult = "rosen_young_adult",
  rourke = "rourke",
  rourke_educational_media = "rourke_educational_media",
  rourke_educational_media_educational_media = "rourke_educational_media_educational_media",
  rourke_publishing = "rourke_publishing",
  routledge = "routledge",
  rr_books = "rr_books",
  rrbooks = "rrbooks",
  ruby_tuesday_books = "ruby_tuesday_books",
  running_press = "running_press",
  saddleback_educational_publishing = "saddleback_educational_publishing",
  salaam_reads_simon_schuster_books_for_young_readers = "salaam_reads_simon_schuster_books_for_young_readers",
  salem_press = "salem_press",
  sandcastle = "sandcastle",
  sara = "sara",
  sara_jordan_publishing = "sara_jordan_publishing",
  saunders = "saunders",
  saunders_book_company = "saunders_book_company",
  saunders_book_company_french = "saunders_book_company_french",
  saunders_ook_ompany = "saunders_ook_ompany",
  sch = "sch",
  scholastic = "scholastic",
  scholastic_canada = "scholastic_canada",
  scholastic_canada_ltd = "scholastic_canada_ltd",
  scholastic_inc = "scholastic_inc",
  scribner = "scribner",
  second_story_press = "second_story_press",
  secret = "secret",
  shell_education = "shell_education",
  signature_editions = "signature_editions",
  simon_pulse = "simon_pulse",
  simon_schuster = "simon_schuster",
  simon_schuster_books_for_young_readers = "simon_schuster_books_for_young_readers",
  simon_schuster_canada = "simon_schuster_canada",
  simon_schuster_paula_wiseman_books = "simon_schuster_paula_wiseman_books",
  simon_spotlight = "simon_spotlight",
  sky_pony = "sky_pony",
  sky_pony_press = "sky_pony_press",
  skyhorse_publishing_inc = "skyhorse_publishing_inc",
  smart_apple_media = "smart_apple_media",
  smartbook_media_inc = "smartbook_media_inc",
  soho_press = "soho_press",
  sourcebooks = "sourcebooks",
  spellbound = "spellbound",
  sportszone = "sportszone",
  spotlight = "spotlight",
  square_fish = "square_fish",
  ssc = "ssc",
  st_martin_s_press = "st_martin_s_press",
  st_martin_s_publishing_group = "st_martin_s_publishing_group",
  stenhouse = "stenhouse",
  sterling_children_s_books = "sterling_children_s_books",
  stl = "stl",
  super_sandcastle = "super_sandcastle",
  talonbooks = "talonbooks",
  taundra = "taundra",
  teacher = "teacher",
  teacher_created_material = "teacher_created_material",
  teacher_created_materials = "teacher_created_materials",
  teachers_college_press = "teachers_college_press",
  the_child_s_world = "the_child_s_world",
  the_child_s_world_inc = "the_child_s_world_inc",
  the_chilld_s_world = "the_chilld_s_world",
  the_conservation_lands_of_ontario = "the_conservation_lands_of_ontario",
  the_innovation_press = "the_innovation_press",
  the_laboratory_school_at_the_dr_eric_jackman_institute_of_child_study_ontario_institute_for_studie = "the_laboratory_school_at_the_dr_eric_jackman_institute_of_child_study_ontario_institute_for_studie",
  the_new_press = "the_new_press",
  the_secret_mountain = "the_secret_mountain",
  toad = "toad",
  tom_doherty_associates = "tom_doherty_associates",
  tradewind_books = "tradewind_books",
  tundra = "tundra",
  tundra_books = "tundra_books",
  university_of_regina_press = "university_of_regina_press",
  university_of_washington_press = "university_of_washington_press",
  utp = "utp",
  viking = "viking",
  viking_children_s_books = "viking_children_s_books",
  viz_media_llc = "viz_media_llc",
  walter_foster_jr = "walter_foster_jr",
  we_do_listen_foundation = "we_do_listen_foundation",
  weigl = "weigl",
  weigl_educational_publishers = "weigl_educational_publishers",
  weigl_publishing = "weigl_publishing",
  west_44_books = "west_44_books",
  wil = "wil",
  wiley = "wiley",
  wilfrid_laurier_university_press = "wilfrid_laurier_university_press",
  windmill_books = "windmill_books",
  zeitgeist = "zeitgeist",
}

export enum ProvinceEnum {
  AB = "AB",
  BC = "BC",
  MB = "MB",
  NB = "NB",
  NL = "NL",
  NS = "NS",
  NT = "NT",
  NU = "NU",
  ON = "ON",
  PE = "PE",
  QC = "QC",
  SK = "SK",
  YT = "YT",
}

/**
 * Attributes for a product item in a Cart
 */
export interface CartProductAttributes {
  productId: string;
  quantity: number;
}

/**
 * Attributes for creating or updating customer information for an order
 */
export interface OrderCustomerProfileAttributes {
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  contactEmail: string;
  title?: string | null;
  institutionName?: string | null;
  subscribed?: boolean | null;
}

/**
 * Attributes for creating or updating product information for an order
 */
export interface OrderProductsAttributes {
  productId: string;
  quantity: number;
}

export interface ProductFilter {
  OR?: ProductFilter[] | null;
  titleContains?: string | null;
  subtitleContains?: string | null;
  eanContains?: string | null;
  isbnContains?: string | null;
}

/**
 * Attributes for creating or updating a shipping address
 */
export interface ShippingAddressAttributes {
  address: string;
  address2?: string | null;
  address3?: string | null;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  contact: string;
  deliveryInstructions?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
