import { useQuery } from '@apollo/client';
import { formatPublicationDate } from 'utilities';

import { CART_PRODUCT_SEARCH } from './graphql/cartProductSearch';

export const useCartProductSearch = (ean: string) => {
  const { data, loading, error } = useQuery(CART_PRODUCT_SEARCH, {
    variables: { ean },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  if (data?.productByEan) {
    const { publicationDate, ...restOfProductDetails } = data.productByEan[0];

    return {
      productDetails: {
        publicationDate: formatPublicationDate(publicationDate),
        ...restOfProductDetails,
      },
      loading,
      error,
    };
  }

  return { productDetails: null, loading, error };
};
