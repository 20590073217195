import React from 'react';
import { Stack } from 'components';

import {
  AccountSection,
  DeliveryMethodSection,
  DeliverySection,
} from './components';
import { CheckoutFormState, DispatchAction } from '../../types';

import * as styles from './InformationSection.module.scss';

interface InformationSectionProps {
  formState: CheckoutFormState;
  dispatch: (args: DispatchAction) => void;
}

const InformationSection = ({
  formState,
  dispatch,
}: InformationSectionProps) => {
  return (
    <div className={styles.InformationSection}>
      <Stack vertical spacing='baseLoose'>
        <AccountSection formState={formState} dispatch={dispatch} />
        <DeliverySection formState={formState} dispatch={dispatch} />
        <DeliveryMethodSection formState={formState} dispatch={dispatch} />
      </Stack>
    </div>
  );
};

export default InformationSection;
