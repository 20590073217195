import React, { useState } from "react";

import { SearchEmptyState, SearchFilters, SearchResults } from "./components";

import * as styles from "./Search.module.scss";

export const SearchContent = () => {
  const [hasResults, setHasResults] = useState(true);

  const searchContent = hasResults ? (
    <div className={styles.Search}>
      <SearchFilters />
      <SearchResults setHasResults={(arg: boolean) => setHasResults(arg)} />
    </div>
  ) : (
    <div className={styles.Search}>
      <SearchEmptyState />
    </div>
  );

  return <div className={styles.SearchWrapper}>{searchContent}</div>;
};
