import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Stack, TextLink } from 'components';
import { ChevronLeft as ChevronLeftIcon } from 'assets/icons';

import translations from './translations/en.json';

import * as styles from './Breadcrumbs.module.scss';

interface BreadcrumbsProps {}

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.Breadcrumbs}>
      {/* {children} */}
      <TextLink onClick={goBack} secondary withUnderline>
        <Stack alignment='center' spacing='extraTight'>
          <Icon source={ChevronLeftIcon} />
          {translations.goBack}
        </Stack>
      </TextLink>
    </div>
  );
};

export default Breadcrumbs;
