import React, { useState } from "react";
import {
  Body,
  DangerouslySetInnerHTML,
  Heading,
  Link,
  Stack,
  Stepper,
  Subheading,
  TextLink,
  TextStyle,
} from "components";
import { formatPrice, mapToFormat } from "utilities";
import { Routes } from "foundation";

import translations from "./translations/en.json";
import { ProductVariantType } from "../../hooks";
import { ProductImage } from "../../../ProductImage";

import * as styles from "./VariantListItem.module.scss";

interface VariantListItemProps {
  product: ProductVariantType;
  onChange: (ean: string, quantity: number) => void;
  beforeRedirect: () => void;
}

const VariantListItem: React.FunctionComponent<VariantListItemProps> = ({
  product,
  onChange,
  beforeRedirect,
}) => {
  const [stepperValue, setStepperValue] = useState(0);
  const {
    author,
    // collectionId,
    // collectionNames,
    // copyrightYear,
    // curriculums,
    // discountedPrice,
    ean,
    // format,
    // genre,
    // handle,
    imageUrl,
    // imprintName,
    // inventory,
    // isbn,
    // itemCode,
    // language,
    // onSaleDate,
    price,
    // publicationDate,
    // publisherName,
    // readingLevel,
    subtitle,
    title,
  } = product;

  const productLink = `${Routes.products}/${ean}`;

  const onChangeCallback = (quantity) => {
    setStepperValue(quantity);
    onChange(ean, quantity);
  };

  return (
    <Stack vertical>
      <Subheading size="extraSmall" textTransform="uppercase">
        {mapToFormat(product.format)}
      </Subheading>
      <Stack>
        <div className={styles.ImageWrapper}>
          <Link to={productLink} beforeRedirect={beforeRedirect}>
            <ProductImage imageUrl={imageUrl} alt={`${title} book cover`} />
          </Link>
        </div>
        <Stack.Item fill>
          <Stack vertical spacing="tight">
            <Stack vertical spacing="extraExtraTight">
              <TextLink
                to={productLink}
                beforeRedirect={beforeRedirect}
                secondary
              >
                <Subheading>{title}</Subheading>
              </TextLink>
              {subtitle && (
                <Body size="small">
                  <TextStyle variation="darker">
                    <DangerouslySetInnerHTML>
                      {subtitle}
                    </DangerouslySetInnerHTML>
                  </TextStyle>
                </Body>
              )}
              {/*
          no series in the db atm 
          {series && (
            <Heading size='extraSmall'>
              <TextStyle variation='darkest'>{series}</TextStyle>
            </Heading>
          )} */}
            </Stack>
            <Heading size="extraExtraSmall">
              <TextStyle variation="strong">
                <DangerouslySetInnerHTML>
                  {`${translations.by} ${author ? author : "N/A"}`}
                </DangerouslySetInnerHTML>
              </TextStyle>
            </Heading>
            <Heading size="small">{formatPrice(price)}</Heading>
            <TextLink
              to={productLink}
              beforeRedirect={beforeRedirect}
              secondary
              withUnderline
            >
              {translations.viewFullDetails}
            </TextLink>
          </Stack>
        </Stack.Item>
        <Stepper
          value={stepperValue}
          updateValue={onChangeCallback}
          min={0}
          max={9999}
        />
      </Stack>
    </Stack>
  );
};

export default VariantListItem;
