import React from 'react';

// todo: move these to a re-mapped types top level file
import { CartProductType, useIsMediumScreen } from 'hooks';

import { useCartProductSearch } from './hooks';
import { ProductDetails, ProductImage, ProductPrice } from './components';

import { Stack } from 'components';

interface ProductListRowProps {
  product: CartProductType;
}

const ProductListRow: React.FunctionComponent<ProductListRowProps> = ({
  product: { productId, quantity },
}: ProductListRowProps) => {
  const { productDetails, loading, error } = useCartProductSearch(productId);
  const isMediumScreen = useIsMediumScreen();

  if (loading) {
    // return <p>Loading</p>;
    return null;
  }

  if (!productDetails || error) {
    return <p>An error occurred</p>;
  }

  const { imageUrl, price, title, ...restOfProduct } = productDetails;

  const product = { ...restOfProduct, title, ean: productId, price };

  return (
    <Stack alignment='leading' distribution='equalSpacing' spacing='baseLoose'>
      <ProductImage imageUrl={imageUrl} alt={`${title} book cover`} />
      <Stack.Item fill spacing='baseLoose'>
        <ProductDetails product={product} quantity={quantity} />
      </Stack.Item>
      {!isMediumScreen ? <ProductPrice price={price} /> : null}
    </Stack>
  );
};

export default ProductListRow;
