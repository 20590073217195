import React from "react";
import * as styles from "./FormatFilter.module.scss";
import { ProductFormatEnum } from "types";
import { mapToFormat } from "utilities";
import { Checkbox, Stack } from "components";

import { SearchFilterSection } from "../SearchFilterSection";
import { useFormatState } from "./hooks/useFormatState";
import translations from "./translations/en.json";

interface FormatFilterProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const FormatFilter = ({ isOpen, toggleIsOpen }: FormatFilterProps) => {
  const productFormatEnumKeys = Object.keys(ProductFormatEnum);
  const { filteredFormats, filteredFormatsCallback } = useFormatState();

  const filterOptions = filteredFormats.map((value, index) => {
    return {
      label: mapToFormat(ProductFormatEnum[productFormatEnumKeys[index]]),
      selected: value,
      toggleChecked: () =>
        filteredFormatsCallback(
          ProductFormatEnum[productFormatEnumKeys[index]]
        ),
    };
  });

  return (
    <SearchFilterSection
      title={translations.title}
      // options={filterOptions}
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
    >
      <Stack vertical spacing="extraTight">
        {filterOptions.map(({ label, selected, toggleChecked }) => {
          return (
            <Checkbox
              label={label}
              checked={selected}
              toggleChecked={toggleChecked}
              key={label}
            />
          );
        })}
      </Stack>
    </SearchFilterSection>
  );
};

export default FormatFilter;
