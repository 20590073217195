import React from 'react';
import * as styles from './Heading.module.scss';

interface HeadingProps {
  size?:
    | 'extraLarge'
    | 'large'
    | 'default'
    | 'small'
    | 'extraSmall'
    | 'extraExtraSmall';
}

const Heading: React.FunctionComponent<HeadingProps> = ({
  size = 'default',
  children,
}) => {
  switch (size) {
    case 'extraLarge':
      return <h1 className={styles.HeadingExtraLarge}>{children}</h1>;
    case 'large':
      return <h2 className={styles.HeadingLarge}>{children}</h2>;
    case 'default':
    // added default here for easier readability for html element returned
    default:
      return <h3 className={styles.HeadingMedium}>{children}</h3>;
    case 'small':
      return <h4 className={styles.HeadingSmall}>{children}</h4>;
    case 'extraSmall':
      return <h5 className={styles.HeadingExtraSmall}>{children}</h5>;
    case 'extraExtraSmall':
      return <h6 className={styles.HeadingExtraExtraSmall}>{children}</h6>;
  }
};

export default Heading;
