import React, { createContext, useContext, useState } from "react";
import { Portal } from "components";

import { PortalRootEnum } from "components/Portal/types";
import { Toast } from "components";
import { useUniqueId } from "hooks";

import * as styles from "./ToastContext.module.scss";

interface AddToastOptions {
  critical?: boolean;
  persistent?: boolean;
}
interface State {
  addToast: (content: React.ReactNode, options: AddToastOptions) => void;
  closeToast: (args: string) => void;
}

const initialState: State = {
  addToast: () => {},
  closeToast: () => {},
};

const ToastContext = createContext<State>(initialState);

const defaultTimeout = 5000;

export const ToastProvider: React.FunctionComponent = ({ children }) => {
  const [activeToasts, setActiveToasts] = useState([]);

  const addToast = (
    content: React.ReactNode,
    { critical = false, persistent = false }: AddToastOptions
  ) => {
    const activeToastIds = activeToasts.map((toast) => toast.id);
    const id = useUniqueId(activeToastIds, "toast");
    const toast = {
      id,
      content,
      critical,
    };
    setActiveToasts([...activeToasts, toast]);
    if (!persistent) {
      setTimeout(() => {
        closeToast(id);
      }, defaultTimeout);
    }
  };

  const closeToast = (id: string) => {
    setActiveToasts((activeToasts) => {
      const activeToastIndex = activeToasts.findIndex(
        (toast) => toast.id === id
      );
      activeToasts.splice(activeToastIndex, 1);
      return [...activeToasts];
    });
  };

  const value = {
    addToast,
    closeToast,
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Portal type={PortalRootEnum.Toast}>
        {activeToasts.length > 0 && (
          <div className={styles.ToastOverlay}>
            <div className={styles.ToastGrid}>
              {activeToasts.map((toast) => (
                <Toast
                  key={toast.id}
                  closeToast={() => closeToast(toast.id)}
                  critical={toast.critical}
                >
                  {toast.content}
                </Toast>
              ))}
            </div>
          </div>
        )}
      </Portal>
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);
