import React, { SyntheticEvent } from 'react';
import * as styles from './StopEventPropagation.module.scss';

interface StopEventPropagationProps {}

const StopEventPropagation: React.FunctionComponent<StopEventPropagationProps> =
  ({ children }) => {
    const stopEventPropagationHelper = (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <div onClick={(event) => stopEventPropagationHelper(event)}>
        {children}
      </div>
    );
  };

export default StopEventPropagation;
