import React, { createContext, useCallback, useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useSearchProducts, ProductType } from "./hooks";
import { ProductOrderBy } from "types";

interface PageState {
  current: number;
  total: number;
  countPerPage: number;
  setPage: (number) => void;
}
interface State {
  searchTerm: string;
  products: ProductType[];
  totalCount: number;
  loading: boolean;
  error: any;
  page: PageState;
  fetchMore: (args) => void;
}

const countPerPage = 30;

const initialState: State = {
  searchTerm: "_",
  products: [],
  totalCount: 0,
  loading: true,
  error: null,
  page: {
    current: 0,
    total: 0,
    countPerPage,
    setPage: (number) => {},
  },
  fetchMore: (args) => {},
};

const SearchContext = createContext<State>(initialState);

export const SearchProvider: React.FunctionComponent = ({ children }) => {
  const [page, setPage] = useState(1);
  const { search } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filteredFormats = JSON.parse(searchParams.get("formats")) ?? [];
  const filteredCollections = JSON.parse(searchParams.get("collection")) ?? [];
  const { min, max } = JSON.parse(searchParams.get("price")) ?? {
    min: "",
    max: "",
  };
  const orderBy =
    JSON.parse(searchParams.get("orderBy")) ?? ProductOrderBy.ean_ASC;

  const searchTerm = search ? decodeURI(search) : "";

  const { products, totalCount, loading, error, fetchMore } = useSearchProducts(
    {
      searchTerm,
      first: countPerPage,
      format: filteredFormats,
      collection: filteredCollections,
      orderBy,
      priceMax: max,
      priceMin: min,
    }
  );

  const pageState = {
    current: page,
    total: Math.max(totalCount / countPerPage),
    countPerPage,
    setPage: (arg: number) => setPage(arg),
  };

  const value = {
    searchTerm,
    products,
    totalCount,
    loading,
    error,
    page: pageState,
    fetchMore,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);
