type UseLocalStorage<T> = [T, (value?: T) => void];

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): UseLocalStorage<T> {
  let item;
  try {
    item = window.localStorage.getItem(key);
  } catch (error) {
    // localStorage sometimes throws, we will just ignore it
  }

  const itemValue: T = item ? (JSON.parse(item) as T) : initialValue;

  const setValue = (value?: T) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // localStorage sometimes throws, we will just ignore it
    }
  };

  return [itemValue, setValue];
}
