import { gql } from "@apollo/client";

export const SEARCH_ALL_PRODUCTS = gql`
  query searchAllProducts(
    $filter: ProductFilter
    $first: Int
    $priceMax: Float
    $priceMin: Float
    $skip: Int
    $format: [ProductFormatEnum!]
    $collection: [ProductCollectionEnum!]
    $orderBy: ProductOrderBy = ean_ASC
  ) {
    productSearch(
      filter: $filter
      first: $first
      priceMax: $priceMax
      priceMin: $priceMin
      skip: $skip
      format: $format
      collection: $collection
      orderBy: $orderBy
    ) {
      products {
        author
        # collectionId
        # collectionNames
        # copyrightYear
        # curriculums
        # discountedPrice
        ean
        format
        # genre
        # handle
        id
        imageUrl
        # imprintName
        # inventory
        # isbn
        # itemCode
        language
        numberOfOtherVariants
        price
        publicationDate
        publisherName
        readingLevel
        subtitle
        title
      }
      totalCount
    }
  }

  # query AllProducts {
  #   allProducts {
  #     id
  #   }
  # }
`;

//npx apollo codegen:generate --localSchemaFile=app/graphql/schema.json --target=typescript --tagName=gql
