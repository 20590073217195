import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { PortalRootEnum } from './types';
import { usePortalManager } from './hooks/usePortalManager';

// import { useUniqueId } from '';

// reference: https://github.com/Shopify/polaris-react/blob/main/src/components/Portal/Portal.tsx
interface PortalProps {
  idPrefix?: string;
  type: PortalRootEnum;
}

const Portal: React.FunctionComponent<PortalProps> = ({ children, type }) => {
  const container = usePortalManager(type);

  if (children && container) {
    return createPortal(children, container);
  } else {
    return null;
  }
};

export default Portal;
