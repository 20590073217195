import React from 'react';
import { Button, Label, Stack } from 'components';

import * as styles from './Stepper.module.scss';

interface StepperProps {
  label?: string | null;
  value: number;
  updateValue: (value: number) => void;
  stepSize?: number;
  min?: number | null;
  max?: number | null;
}

const Stepper = ({
  label = null,
  value,
  updateValue,
  stepSize = 1,
  min = null,
  max = null,
}: StepperProps) => {
  const stepDownCallback = () => {
    const newValue = value - stepSize;
    if (min !== null && newValue <= min) {
      updateValue(min);
    } else {
      updateValue(newValue);
    }
  };
  const stepUpCallback = () => {
    const newValue = value + stepSize;
    if (max !== null && newValue >= max) {
      updateValue(max);
    } else {
      updateValue(newValue);
    }
  };

  const onChange = (value) => {
    {
      if (max !== null && value >= max) {
        updateValue(max);
      } else if (min !== null && value <= min) {
        updateValue(min);
      } else {
        updateValue(parseInt(value));
      }
    }
  };

  const stepperContent = (
    <Stack spacing='none'>
      <Button
        disabled={min !== null && value <= min}
        onClick={stepDownCallback}
        attached={{ right: true }}
      >
        -
      </Button>
      <input
        id={label}
        className={styles.StepperInput}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={value}
        type='number'
        min={min}
        max={max}
      />
      <Button
        disabled={max !== null && value >= max}
        onClick={stepUpCallback}
        attached={{ left: true }}
      >
        +
      </Button>
    </Stack>
  );

  if (!label) {
    return stepperContent;
  }

  return (
    <Stack vertical>
      <Label labelFor={label}>{label}</Label>
      {stepperContent}
    </Stack>
  );
};

export default Stepper;
