import { useQuery } from '@apollo/client';
import { PRODUCT_DETAILS } from './graphql/productDetails';

export const useProductDetails = (ean: string) => {
  const { data, loading, error } = useQuery(PRODUCT_DETAILS, {
    variables: { ean },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  if (data?.productVariantsByEan) {
    const activeVariant = data.productVariantsByEan.find(
      (variant) => variant.ean === ean
    );

    const {
      author,
      collectionId,
      collectionNames,
      copyrightYear,
      curriculums,
      discountedPrice,
      format,
      genre,
      handle,
      imageUrl,
      imprintName,
      inventory,
      isbn,
      itemCode,
      language,
      longDescription,
      numberOfIllustrations,
      numberOfPages,
      onSaleDate,
      price,
      publicationDate,
      publisherName,
      readingLevel,
      subtitle,
      title,
    } = activeVariant;

    const overview = {
      title,
      subtitle,
      series: null,
      author,
      publisher: publisherName,
      description: longDescription,
      imageUrl,
    };

    const transaction = {
      ean,
      formats: data.productVariantsByEan.map((variant) => {
        return { ean: variant.ean, format: variant.format };
      }),
      ourPrice: price,
    };

    const metaData = {
      primary: [{ readingLevel }],
      secondary: [
        [{ format }, { isbn: ean }, { copyrightYear }, { language }, { genre }],
        [{ numberOfPages }, { collectionNames }],
      ],
    };

    return {
      productDetails: { overview, transaction, metaData },
      loading,
      error,
    };
  }

  return { productDetails: null, loading, error };
};
