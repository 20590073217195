import React from 'react';
import { Stack } from 'components';
import { ProductFormatEnum } from 'types';
import { mapToFormat, mapToProductProp } from 'utilities';

import { MetaCell } from './components';
import * as styles from './ProductMeta.module.scss';

interface GenericMetaData {
  [name: string]: string;
}
interface MetaDataProps {
  primary: GenericMetaData[];
  secondary: GenericMetaData[][];
}
export interface ProductMetaProps {
  metaData: MetaDataProps;
}

const ProductMeta = ({
  metaData: { primary, secondary },
}: ProductMetaProps) => {
  const primarySection = (
    <div className={styles.Primary}>
      <Stack vertical spacing='baseLoose'>
        {primary.map((meta) => {
          const metaKey = Object.keys(meta)[0];
          const metaValue = meta[metaKey];

          return (
            <MetaCell title={mapToProductProp(metaKey)} key={metaKey} primary>
              {metaValue}
            </MetaCell>
          );
        })}
      </Stack>
    </div>
  );

  const [secondaryLeft, secondaryRight] = secondary;
  const secondarySection = (
    <div className={styles.Secondary}>
      <div className={styles.SecondaryLeft}>
        <Stack vertical spacing='baseLoose'>
          {secondaryLeft.map((meta) => {
            const metaKey = Object.keys(meta)[0];
            const metaValue = meta[metaKey];

            if (metaKey === 'format') {
              return (
                <MetaCell title={mapToProductProp(metaKey)} key={metaKey}>
                  {mapToFormat(metaValue as ProductFormatEnum)}
                </MetaCell>
              );
            }

            return (
              <MetaCell title={mapToProductProp(metaKey)} key={metaKey}>
                {metaValue}
              </MetaCell>
            );
          })}
        </Stack>
      </div>
      <div className={styles.SecondaryRight}>
        <Stack vertical spacing='baseLoose'>
          {secondaryRight.map((meta) => {
            const metaKey = Object.keys(meta)[0];
            const metaValue = meta[metaKey];

            // handle 0 pages
            if (metaKey === 'numberOfPages' && metaValue === '0') {
              return (
                <MetaCell title={mapToProductProp(metaKey)} key={metaKey}>
                  {null}
                </MetaCell>
              );
            }

            return (
              <MetaCell title={mapToProductProp(metaKey)} key={metaKey}>
                {metaValue}
              </MetaCell>
            );
          })}
        </Stack>
      </div>
    </div>
  );

  return (
    <div className={styles.ProductMeta}>
      {primarySection}
      {secondarySection}
    </div>
  );
};

export default ProductMeta;
