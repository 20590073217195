// // client
// import { ApolloClient } from 'apollo-client';
// // cache
// import { InMemoryCache } from 'apollo-cache-inmemory';
// // links
// import { HttpLink } from 'apollo-link-http';
// import { onError } from 'apollo-link-error';
// import { ApolloLink, Observable } from 'apollo-link';

import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

export const createCache = () => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          productSearch: {
            keyArgs: ["filter", "format", "collection", "orderBy"],
            merge(existing = { products: [] }, incoming, { args }) {
              if (existing.products.length === 0) {
                return incoming;
              }

              // const eans = existing.products.map((product) => product.ean);
              // const filteredIncoming = incoming.products.filter(
              //   (inc) => !eans.includes(inc.ean)
              // );

              return {
                ...incoming,
                products: [...existing.products, ...incoming.products],
              };
            },
          },
        },
      },
    },
  });
  if (process.env.NODE_ENV === "development") {
    window.secretVariableToStoreCache = cache;
  }
  return cache;
};

// getToken from meta tags
const getToken = () =>
  document.querySelector('meta[name="csrf-token"]').getAttribute("content");
const token = getToken();
const setTokenForOperation = async (operation) =>
  operation.setContext({
    headers: {
      "X-CSRF-Token": token,
    },
  });
// link with token
const createLinkWithToken = () =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle;
        Promise.resolve(operation)
          .then(setTokenForOperation)
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));
        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

const logError = (error) => console.error(error);
// create error link
const createErrorLink = () =>
  onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      logError("GraphQL - Error", {
        errors: graphQLErrors,
        operationName: operation.operationName,
        variables: operation.variables,
      });
    }
    if (networkError) {
      logError("GraphQL - NetworkError", networkError);
    }
  });

const createHttpLink = () =>
  new HttpLink({
    uri: "/graphql",
    credentials: "include",
  });

export const createClient = (cache, requestLink) => {
  return new ApolloClient({
    link: from([createErrorLink(), createLinkWithToken(), createHttpLink()]),
    cache,
  });
};
