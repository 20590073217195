import React, { useCallback } from 'react';
import { CartProductType, useCart, useCartTotal } from 'hooks';
import { Button, Icon, Stack } from 'components';
import { Download as DownloadIcon, Print as PrintIcon } from 'assets/icons';
import { formatPrice, mapSnakeCaseToTitleCase, mapToFormat } from 'utilities';
import { useProductDetailsForCSV } from './hooks/useProductDetailsForCSV';

import translations from './translations/en.json';

import * as styles from './CartActions.module.scss';

interface CartActionsProps {
  cart: CartProductType[];
}

const print = () => {
  window.print();
};

const CartActions: React.FunctionComponent<CartActionsProps> = ({ cart }) => {
  const {
    discount: { value: appliedDiscount },
  } = useCart();

  const {
    cartTotals,
    loading: cartTotalLoading,
    error: cartError,
  } = useCartTotal({
    products: cart,
    discountCode: appliedDiscount,
  });

  const {
    products,
    loading: productDetailLoading,
    error: productDetailError,
  } = useProductDetailsForCSV(cart.map((item) => item.productId));

  const loading = cartTotalLoading || productDetailLoading;
  const error = !!cartError || !!productDetailError;

  const downloadCSV = useCallback(() => {
    if (!products) {
      return;
    }

    const rows = [
      ['Guest'],
      ['CrossCan Cart List'],
      ['TITLE', 'PUBLISHER', 'FORMAT', 'ISBN', 'PRICE', 'QTY', 'TOTAL'],
    ];

    products.forEach(({ ean, format, price, publisherName, title }) => {
      const quantity = cart.find((item) => item.productId === ean).quantity;
      rows.push([
        title,
        mapSnakeCaseToTitleCase(publisherName),
        mapToFormat(format),
        ean,
        price,
        quantity,
        formatPrice(price * quantity),
      ]);
    });

    rows.push([
      'SUBTOTAL',
      '',
      '',
      '',
      '',
      '',
      formatPrice(cartTotals.subtotal),
    ]);
    rows.push([
      'DISCOUNT',
      '',
      '',
      '',
      '',
      '',
      formatPrice(cartTotals.discountedAmount),
    ]);
    rows.push(['TOTAL', '', '', '', '', '', formatPrice(cartTotals.total)]);

    let csvContent =
      'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');

    const currentDate = new Date();

    const encodedUri = encodeURI(csvContent);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `crosscan_list-${currentDate.toISOString().split('T')[0]}.csv`
    );
    document.body.appendChild(link); // Required for FF

    link.click();
  }, [products, cartTotals]);

  return (
    <div className={styles.CartActions}>
      <Stack spacing='none'>
        <Button
          label={translations.csv}
          onClick={downloadCSV}
          attached={{ right: true }}
          disabled={!products || loading || error}
        >
          <Icon source={DownloadIcon} fill='base' />
        </Button>
        <Button
          label={translations.print}
          onClick={print}
          attached={{ left: true }}
        >
          <Icon source={PrintIcon} fill='base' />
        </Button>
      </Stack>
    </div>
  );
};

export default CartActions;
