import React from 'react';
import { Heading } from 'components';
import { formatPrice } from 'utilities';

interface ProductPriceProps {
  price: number;
}

const ProductPrice = ({ price }: ProductPriceProps) => {
  return (
    <Heading size='small'>
      {formatPrice(price, {
        hideCurrency: true,
      })}
    </Heading>
  );
};

export default ProductPrice;
