import React from "react";
import { Heading, Stack, TextLink, TextStyle } from "components";
import { Routes } from "foundation";
import cataloguesData from "assets/catalogues/catalogues.json";
import { CategoryEnum } from "sections/Catalogues/types";
import { Catalogue } from "sections/Catalogues/components/Catalogue";

import * as styles from "./CatalogueSection.module.scss";

import translations from "./translations/en.json";

interface CatalogueSectionProps {
  category: CategoryEnum;
}

const CatalogueSection = ({ category }: CatalogueSectionProps) => {
  const title = translations[category].title;
  const catalogues = Object.entries(cataloguesData[category]).slice(0, 4);

  const link = (
    <TextLink to={`${Routes.catalogue}/${category}`}>
      {translations[category].linkContent}
    </TextLink>
  );

  return (
    <Stack vertical spacing="baseLoose">
      <Heading size="small">
        <TextStyle variation="brand">{title}</TextStyle>
      </Heading>
      <div className={styles.CatalogueSection}>
        {catalogues.map(([key, publisherCatalogues]) => {
          return (
            <Catalogue
              category={category}
              publisherCatalogues={publisherCatalogues}
              key={key}
            />
          );
        })}
      </div>
      {link}
    </Stack>
  );
};

export default CatalogueSection;
