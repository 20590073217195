import React from "react";
import { Button, Heading, Icon, Stack } from "components";
import { Close as CloseIcon } from "assets/icons";

import * as styles from "./Header.module.scss";

export type TitleType = JSX.Element | string;

interface HeaderProps {
  title: TitleType;
  closeModal: () => void;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  title,
  closeModal,
}) => {
  return (
    <div className={styles.Header}>
      <Stack distribution="equalSpacing" alignment="center">
        <Heading size="small">{title}</Heading>
        <Button plain onClick={closeModal} square>
          <Icon source={CloseIcon} fill="base" />
        </Button>
      </Stack>
    </div>
  );
};

export default Header;
