import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUpdateSearchParams } from "hooks";
import { ProductCollectionEnum } from "types";

const searchParamKey = "collection";

export const useCollectionState = () => {
  const productCollectionEnumKeys = Object.keys(ProductCollectionEnum);
  const { search } = useLocation();
  const [filteredCollections, setFilteredCollections] = useState<boolean[]>(
    new Array(productCollectionEnumKeys.length).fill(false)
  );
  const { updateSearchParams } = useUpdateSearchParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const json = function (raw) {
      try {
        return JSON.parse(raw);
      } catch (e) {
        return [];
      }
    };
    let collectionParam = json(searchParams.get(searchParamKey));

    if (!collectionParam) {
      return;
    }

    const formattedCollectionParam = Array.isArray(collectionParam)
      ? collectionParam
      : [collectionParam];

    const newFilteredCollectionsCheckState = new Array(
      productCollectionEnumKeys.length
    ).fill(false);

    productCollectionEnumKeys.forEach((key, index) => {
      if (formattedCollectionParam.includes(key)) {
        newFilteredCollectionsCheckState[index] = true;
      }
    });

    setFilteredCollections([...newFilteredCollectionsCheckState]);
  }, [search, setFilteredCollections]);

  const filteredCollectionsCallback = useCallback(
    (collection: ProductCollectionEnum) => {
      const indexOfCollection = productCollectionEnumKeys.indexOf(collection);
      filteredCollections[indexOfCollection] =
        !filteredCollections[indexOfCollection];
      const refFilteredCollections = [...filteredCollections];

      let args = [];
      refFilteredCollections.forEach(
        (checked, i) =>
          checked &&
          args.push(ProductCollectionEnum[productCollectionEnumKeys[i]])
      );

      updateSearchParams({ name: searchParamKey, value: args });
    },
    [filteredCollections]
  );

  return { filteredCollections, filteredCollectionsCallback };
};
