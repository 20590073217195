import { gql } from '@apollo/client';

export const CART_TOTAL = gql`
  query cartTotal(
    $products: [CartProductAttributes!]!
    $province: ProvinceEnum
    $discountCode: String
  ) {
    cartTotal(
      products: $products
      province: $province
      discountCode: $discountCode
    ) {
      discount {
        code
        discountedAmount
        shippingIncluded
        validDiscount
      }
      shippingTotal
      subtotal
      total
      totalTax
    }
  }
`;
