import React from 'react';
import {
  Stack,
  Button,
  Heading,
  Subheading,
  TextStyle,
  Body,
} from 'components';
import { formatPrice } from 'utilities';

import translations from './translations/en.json';

interface TotalSectionProps {
  total: number;
  tax?: number;
  shipping?: number;
  freeShipping?: boolean;
  checkout?: boolean;
  callback: () => void;
}

const TotalSection = ({
  total,
  checkout = false,
  callback,
  tax,
  shipping,
  freeShipping = false,
}: TotalSectionProps) => {
  const shippingContent = (() => {
    if (freeShipping) {
      return formatPrice(0);
    }

    return shipping ? formatPrice(shipping) : '—';
  })();

  const shippingRow = (
    <Stack distribution='equalSpacing'>
      <Subheading size='extraSmall' textTransform='uppercase'>
        <TextStyle variation='dark'>{translations.shipping.title}:</TextStyle>
      </Subheading>
      <Heading size='extraSmall'>
        <TextStyle variation='weak'>{shippingContent}</TextStyle>
      </Heading>
    </Stack>
  );

  const taxRow = (
    <Stack distribution='equalSpacing'>
      <Subheading size='extraSmall' textTransform='uppercase'>
        <TextStyle variation='dark'>{translations.tax.cad}:</TextStyle>
      </Subheading>
      <Heading size='extraSmall'>
        <TextStyle variation='weak'>{tax ? formatPrice(tax) : '—'}</TextStyle>
      </Heading>
    </Stack>
  );

  return (
    <Stack vertical>
      {checkout && shippingRow}
      {checkout && taxRow}
      <Stack distribution='equalSpacing'>
        <Subheading size='extraSmall' textTransform='uppercase'>
          <TextStyle variation='dark'>
            {checkout ? translations.total : translations.estimatedTotal}:
          </TextStyle>
        </Subheading>
        <Heading size='small'>{formatPrice(total)}</Heading>
      </Stack>
      {!checkout && <Body size='small'>{translations.taxesDisclaimer}</Body>}
      <Button
        fullWidth
        primary
        onClick={callback}
        form={checkout ? 'checkoutForm' : null}
        type={checkout ? 'submit' : null}
      >
        {checkout ? translations.cta.placeOrder : translations.cta.goToCheckOut}
      </Button>
    </Stack>
  );
};

export default TotalSection;
