import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Body,
  Button,
  Hairline,
  Heading,
  Select,
  Stack,
  Stepper,
  Subheading,
  TextStyle,
} from "components";
import { ProductFormatEnum } from "types";
import { formatPrice, mapToFormat } from "utilities";
import { Routes } from "foundation";
import { useCart, useToast } from "hooks";

import translations from "./translations/en.json";
import * as styles from "./TransactionPanel.module.scss";

interface FormatsType {
  ean: string;
  format: ProductFormatEnum;
}
export interface TransactionPanelProps {
  listPrice?: number;
  ourPrice: number;
  ean: string;
  formats: FormatsType[];
}

const TransactionPanel = ({
  listPrice,
  ourPrice,
  ean,
  formats,
}: TransactionPanelProps) => {
  const [selectedFormat, setSelectedFormat] = useState(ean);
  let history = useHistory();
  const [quantity, setQuantity] = useState(0);
  const { updateCartItems } = useCart();
  const { addToast } = useToast();
  const toastContent = (
    <Body>
      <TextStyle variation="light">
        {quantity === 1
          ? `${quantity} ${translations.itemsAddedToCart.singular}`
          : `${quantity} ${translations.itemsAddedToCart.plural}`}
      </TextStyle>
    </Body>
  );

  const formatOptions = formats.map((variant) => {
    return {
      value: variant.ean,
      content: mapToFormat(variant.format),
    };
  });

  const formatOnChangeCallback = (value) => {
    history.replace(`${Routes.products}/${value}`);
    setSelectedFormat(ProductFormatEnum[value]);
  };

  const addToCartCallback = () => {
    updateCartItems([{ productId: ean, quantity }]);
    addToast(toastContent);
    setQuantity(0);
  };

  const pricesSection = (
    <Stack vertical>
      {listPrice && (
        <Stack distribution="equalSpacing" alignment="center">
          <Subheading size="extraSmall" textTransform="uppercase">
            {translations.listPrice}
          </Subheading>
          <Body textDecoration="strikeThrough">$56.00</Body>
        </Stack>
      )}
      <Stack distribution="equalSpacing" alignment="center">
        <Subheading size="extraSmall" textTransform="uppercase">
          {translations.ourPrice}
        </Subheading>
        <Heading size="small">
          {formatPrice(ourPrice, { currency: "CAD" })}
        </Heading>
      </Stack>
    </Stack>
  );

  return (
    <div className={styles.TransactionPanel}>
      {pricesSection}
      <Hairline />
      <Stack vertical spacing="baseTight">
        <Select
          label={translations.format}
          value={selectedFormat}
          options={formatOptions}
          onChange={formatOnChangeCallback}
          fullWidth
        />
        <Stepper
          label={translations.quantity}
          value={quantity}
          updateValue={setQuantity}
          min={0}
          max={1000}
        />
        <Button
          onClick={addToCartCallback}
          disabled={quantity <= 0}
          primary
          fullWidth
        >
          {translations.addQuantityCTA}
        </Button>
      </Stack>
    </div>
  );
};

export default TransactionPanel;
