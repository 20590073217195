import React from "react";
import { Heading, Stack, Body } from "components";
import { CategoryEnum } from "sections/Catalogues/types";
import { CatalogueSection } from "./components/CatalogueSection";

import * as styles from "./CataloguesSection.module.scss";

import translations from "./translations/en.json";

const categories = [
  CategoryEnum.elementary,
  CategoryEnum.highSchool,
  CategoryEnum.fiction,
  CategoryEnum.classroom,
  CategoryEnum.french,
];

const CataloguesSection: React.FunctionComponent = () => {
  return (
    <div className={styles.CataloguesSection}>
      <Stack vertical spacing="extraExtraLoose">
        <div className={styles.CataloguesSectionOverview}>
          <Stack vertical spacing="loose">
            <Heading size="large">
              {translations.cataloguesSection.title}
            </Heading>
            <div>
              <Body>{translations.cataloguesSection.description.lineOne}</Body>
              <Body>{translations.cataloguesSection.description.lineTwo}</Body>
            </div>
          </Stack>
        </div>

        {categories.map((category) => (
          <CatalogueSection
            category={category}
            key={`${category}-catalogue-section`}
          />
        ))}
      </Stack>
    </div>
  );
};

export default CataloguesSection;
