import React from 'react';
import { Stack } from 'components';
import { CartProductType } from 'hooks';

import { CartSummary, HelpCard } from './components';
import * as styles from './TransactionSection.module.scss';

interface BaseTransactionSectionProps {
  cart: CartProductType[];
  totalNumberOfProducts: number;
}

interface ViewCartTransactionSectionProps extends BaseTransactionSectionProps {
  checkout?: boolean;
  callback: () => void;
}
interface CheckoutTransactionSectionProps extends BaseTransactionSectionProps {
  checkout: boolean;
  callback?: () => void;
}
type TransactionSectionProps =
  | CheckoutTransactionSectionProps
  | ViewCartTransactionSectionProps;

const TransactionSection = ({
  cart,
  totalNumberOfProducts,
  checkout = false,
  callback,
}: TransactionSectionProps) => {
  return (
    <div className={styles.TransactionSection}>
      <Stack vertical spacing='baseLoose'>
        <CartSummary
          cart={cart}
          totalNumberOfProducts={totalNumberOfProducts}
          checkout={checkout}
          callback={callback}
        />
        <HelpCard />
      </Stack>
    </div>
  );
};

export default TransactionSection;
