import React, { useState } from "react";
import { Button, Caption, Stack, TextStyle } from "components";
import { useIsMediumScreen } from "hooks";
import { classNames } from "utilities";

import * as styles from "./TextField.module.scss";
interface TextFieldProps {
  placeholder?: string;
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  value: string | number;
  onChange: (value) => void;
  dropdown?: {
    // to do
  };
  action?: {
    onClick: () => void;
    primary?: boolean;
    content: string;
  };
  validate?: (args) => boolean | string;
  onValidateError?: (args: boolean) => void;
  required?: boolean;
  min?: number;
  max?: number;
}

const TextField = ({
  value,
  onChange,
  placeholder = "",
  type = "text",
  action,
  validate = (args) => true,
  onValidateError = (args) => {},
  required = false,
  min = null,
  max = null,
}: TextFieldProps) => {
  const [hasError, setHasError] = useState<boolean | string>(false);
  const isMediumScreen = useIsMediumScreen();

  const TextFieldInputClassNames = classNames(
    styles.TextFieldInput,
    action && styles.WithAction,
    hasError != false && styles.HasError
  );

  const validateCallback = () => {
    const isValid = validate(value);
    if (isValid === true) {
      setHasError(false);
      onValidateError(isValid === true);
    } else {
      setHasError(isValid);
      onValidateError(false);
    }
    // isValid === true ? setHasError(false) : setHasError(isValid);
  };

  let actionItem;
  let handleKeyDown = (arg) => {};

  if (action) {
    const { onClick, primary = true, content } = action;
    actionItem = !isMediumScreen ? (
      <Button onClick={onClick} primary={primary} attached={{ left: true }}>
        {content}
      </Button>
    ) : (
      <Button onClick={onClick} primary={primary} fullWidth>
        {content}
      </Button>
    );

    handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onClick();
      }
    };
  }

  if (isMediumScreen) {
    return (
      <Stack vertical spacing="extraTight">
        <input
          className={TextFieldInputClassNames}
          type={type}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          placeholder={placeholder}
          value={value}
          onKeyDown={action && handleKeyDown}
          onBlur={validateCallback}
          required={required}
          min={min}
          max={max}
        />
        {hasError && (
          <Caption>
            <TextStyle variation="critical">{hasError}</TextStyle>
          </Caption>
        )}
        {action && actionItem}
      </Stack>
    );
  }

  return (
    <Stack vertical spacing="extraExtraTight">
      <Stack spacing="none" alignment="center">
        <Stack.Item fill spacing="none">
          <input
            className={TextFieldInputClassNames}
            type={type}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            placeholder={placeholder}
            value={value}
            onKeyDown={action && handleKeyDown}
            onBlur={validateCallback}
            required={required}
            min={min}
            max={max}
          />
        </Stack.Item>
        {action && actionItem}
      </Stack>
      {hasError && (
        <Caption>
          <TextStyle variation="critical">{hasError}</TextStyle>
        </Caption>
      )}
    </Stack>
  );
};

export default TextField;
