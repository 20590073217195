import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { classNames } from "utilities";

import * as styles from "./Link.module.scss";

interface LinkProps {
  to: string;
  searchParams?: string;
  hash?: string;
  inline?: boolean;
  beforeRedirect?: () => void;
  external?: boolean;
}

const Link: React.FunctionComponent<LinkProps> = ({
  children,
  to,
  searchParams,
  hash,
  inline = false,
  external = false,
  beforeRedirect = () => {},
}) => {
  const linkClassNames = classNames(styles.Link, inline && styles.Inline);
  let history = useHistory();

  function handleClick(e) {
    if (!external) {
      e.preventDefault();
      beforeRedirect();
      requestAnimationFrame(() => history.push(`${to}`));
    }
  }

  return (
    <div className={linkClassNames}>
      {/* we pass href prop here to get native link accessibility */}
      <a onClick={handleClick} href={to} target={external ? "_blank" : "_self"}>
        {children}
      </a>
    </div>
  );
};

export default Link;
