import React from 'react';
import { Banner, Body, Heading, Stack, TextField } from 'components';

import {
  CheckoutFormActions,
  CheckoutFormState,
  DispatchAction,
} from '../../../../types';

import translations from './translations/en.json';

import * as styles from './DeliveryMethodSection.module.scss';

interface DeliveryMethodSectionProps {
  formState: CheckoutFormState;
  dispatch: (args: DispatchAction) => void;
}

const DeliveryMethodSection: React.FunctionComponent<DeliveryMethodSectionProps> =
  ({ formState, dispatch }: DeliveryMethodSectionProps) => {
    const onChangeCallback = (type: CheckoutFormActions) => {
      return (value) =>
        dispatch({
          type,
          payload: value,
        });
    };

    return (
      <Stack vertical spacing='baseLoose'>
        <Heading size='small'>{translations.title}</Heading>
        <Banner>
          <Body size='small'>{translations.bannerDisclaimer}</Body>
        </Banner>
        <Stack vertical spacing='extraTight'>
          <TextField
            value={formState.deliveryInstructions}
            onChange={onChangeCallback(
              CheckoutFormActions.deliveryInstructions
            )}
            placeholder={translations.deliveryInstructionsPlaceholder}
            type='text'
          />
        </Stack>
      </Stack>
    );
  };

export default DeliveryMethodSection;
