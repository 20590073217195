export const useUniqueId = (existingIds: string[], prefix?: string) => {
  let suffix = existingIds.length;
  let uniqueId = prefix ? `${prefix}-${suffix.toString()}` : suffix.toString();

  while (existingIds.includes(uniqueId)) {
    suffix += 1;
    uniqueId = prefix ? `${prefix}-${suffix.toString()}` : suffix.toString();
  }

  return uniqueId;
};
