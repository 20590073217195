import React, { useState } from "react";
import {
  Badge,
  Body,
  Button,
  Caption,
  Checkbox,
  Hairline,
  Heading,
  Icon,
  Image,
  Modal,
  Select,
  Stack,
  Stepper,
  Subheading,
  TextField,
  TextStyle,
} from "components";
import { Close as CloseIcon } from "assets/icons";
import { useToast } from "hooks";

function ComponentPage() {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => setModalOpen(false);

  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");

  const [checked, setChecked] = useState(false);

  const [selectValue, setSelectValue] = useState("Red");

  const selectOptions = [
    { content: "Red", value: "Red" },
    { content: "Blue", value: "Blue" },
    { content: "Green", value: "Green" },
    { content: "Yellow", value: "Yellow" },
  ];

  const [stepperValue, setStepperValue] = useState(0);
  const [stepperValue2, setStepperValue2] = useState(0);

  const { addToast } = useToast();
  const toastContent = (
    <Body>
      <TextStyle variation="light">This is some content</TextStyle>
    </Body>
  );
  const triggerAddToast = () => {
    addToast(toastContent);
  };

  const secondToastContent = (
    <Body>
      <TextStyle variation="light">
        This is a persistent toast that is critical This is a persistent toast
        that is critical This is a persistent toast that is critical This is a
        persistent toast that is critical This is a persistent toast that is
        critical This is a persistent toast that is critical This is a
        persistent toast that is critical This is a persistent toast that is
        critical This is a persistent toast that is critical
      </TextStyle>
    </Body>
  );
  const triggerAddSecondToast = () => {
    addToast(secondToastContent, { critical: true, persistent: true });
  };

  return (
    <div style={{ padding: "2rem" }}>
      <Stack vertical spacing="extraLoose">
        <Stack.Item spacing="extraLoose">
          <Heading size="extraLarge">Typography</Heading>
          <Heading size="extraLarge">Heading - Extra Large</Heading>
          <Heading size="large">Heading - Large</Heading>
          <Heading>Heading - Medium</Heading>
          <Heading size="small">Heading - Small</Heading>
          <Heading size="extraSmall">Heading - Extra Small</Heading>
          <Heading size="extraExtraSmall">Heading - Extra Extra Small</Heading>
          <Subheading>Subheading - Medium</Subheading>
          <Subheading size="extraSmall">Subheading - Extra Small</Subheading>
          <Body>Body - Medium</Body>
          <Body size="small">Body - Small</Body>
          <Caption>Caption</Caption>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Stack vertical spacing="extraLoose">
            <Heading size="extraLarge">Buttons</Heading>
            <Subheading>Primary</Subheading>
            <Button primary onClick={() => {}}>
              Primary
            </Button>
            <Button primary onClick={() => {}} disabled>
              Primary
            </Button>

            <Subheading>Secondary</Subheading>
            <Button onClick={() => {}}>Secondary</Button>
            <Button onClick={() => {}} disabled>
              Secondary
            </Button>

            <Subheading>Plain</Subheading>
            <Button plain onClick={() => {}}>
              Plain
            </Button>
          </Stack>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Heading size="extraLarge">Badge</Heading>
          <Badge>Default</Badge>
          <Badge status="warning">Warning</Badge>
          <Badge status="error">Error</Badge>
          <Badge status="branded">Branded</Badge>
          <Badge status="sale">Sale</Badge>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Heading size="extraLarge">Overlays</Heading>
          <Subheading>Modal</Subheading>
          <Button
            primary
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
          >
            {modalOpen ? "Modal is open" : "Modal is closed"}
          </Button>
          <Modal
            open={modalOpen}
            onModalClose={closeModal}
            header={
              <Modal.Header title="Modal title" closeModal={closeModal} />
            }
            footer={<Modal.Footer>Yo</Modal.Footer>}
          >
            <p>hello</p>
          </Modal>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Subheading>Toast</Subheading>
          <Button primary onClick={triggerAddToast}>
            Trigger toast
          </Button>
          <Button onClick={triggerAddSecondToast}>Trigger toast</Button>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Heading size="extraLarge">Layout</Heading>
          <Subheading>Stack</Subheading>
          <Subheading size="extraSmall">Default</Subheading>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Stack>
            <Stack.Item fill>Filled item</Stack.Item>
            <Badge>Default</Badge>
            <Badge status="warning">Warning</Badge>
            <Badge status="error">Error</Badge>
            <Badge status="branded">Branded</Badge>
            <Badge status="sale">Sale</Badge>
          </Stack>
          <Subheading size="extraSmall">Vertical</Subheading>

          <Stack vertical>
            <Badge>Default</Badge>
            <Badge status="warning">Warning</Badge>
            <Badge status="error">Error</Badge>
            <Badge status="branded">Branded</Badge>
            <Badge status="sale">Sale</Badge>
          </Stack>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Heading size="extraLarge">Icon</Heading>
          <Stack>
            <Icon source={CloseIcon} fill="base" />
            <Icon source={CloseIcon} fill="subdued" />
            <Icon source={CloseIcon} fill="brand" />
            <Icon source={CloseIcon} fill="critical" />
            <Icon source={CloseIcon} fill="warning" />
            <Icon source={CloseIcon} fill="success" />
            <Icon source={CloseIcon} fill="link" />
          </Stack>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Heading size="extraLarge">TextField</Heading>
          <Stack>
            <TextField value={input1} onChange={setInput1} />
            <TextField
              value={input2}
              onChange={setInput2}
              action={{
                onClick: () => console.log(input2),
                content: "test",
              }}
            />
          </Stack>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Heading size="extraLarge">Checkbox</Heading>
          <Stack>
            <Checkbox
              label="Checkbox"
              checked={checked}
              toggleChecked={() => setChecked(!checked)}
            />
          </Stack>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Heading size="extraLarge">Select</Heading>
          <Stack>
            <Select
              label="0 - 10"
              value={selectValue}
              options={selectOptions}
              onChange={setSelectValue}
            />
          </Stack>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Heading size="extraLarge">Stepper</Heading>
          <Stack>
            <Stepper
              label="Stepper 0-10"
              value={stepperValue}
              updateValue={setStepperValue}
              min={0}
              max={10}
            />
            <Stepper
              label="Stepper no limit"
              value={stepperValue2}
              updateValue={setStepperValue2}
            />
          </Stack>
        </Stack.Item>

        <Stack.Item spacing="extraLoose">
          <Heading size="extraLarge">Image</Heading>
          <Stack>
            <div style={{ width: "200px" }}>
              <Image
                source="https://crosscan.s3.us-east-2.amazonaws.com/9780275981778.jpg"
                alt=""
                fallback={<div>fallback</div>}
              />
            </div>
            <Image
              source="https://this.will.not.return.an.image.test.com/someimage.png"
              alt=""
              fallback={<div>fallback</div>}
            />
          </Stack>
        </Stack.Item>

        <Stack.Item spacing="extraLoose" fullWidth>
          <Heading size="extraLarge">Decoration</Heading>
          <Hairline />
          <Hairline spacing="none" />
        </Stack.Item>
      </Stack>
    </div>
  );
}

export default ComponentPage;
