import React from 'react';
import { classNames } from 'utilities';

import * as styles from './Subheading.module.scss';

interface SubheadingProps {
  size?: 'default' | 'extraSmall';
  textTransform?: 'none' | 'uppercase';
}

const Subheading: React.FunctionComponent<SubheadingProps> = ({
  size = 'default',
  textTransform = 'none',
  children,
}) => {
  const subheadingClassnames = classNames(
    styles.Subheading,
    size === 'default' && styles.Medium,
    size === 'extraSmall' && styles.ExtraSmall,
    textTransform === 'uppercase' && styles.Uppercase
  );

  switch (size) {
    case 'default':
    // added default here for easier readability for html element returned
    default:
      return <h5 className={subheadingClassnames}>{children}</h5>;
    case 'extraSmall':
      return <h6 className={subheadingClassnames}>{children}</h6>;
  }
};

export default Subheading;
