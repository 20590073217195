import React, { useCallback, useState } from "react";
import { Collapsible, TextField, TextLink, Stack } from "components";
import { useSearchUrl } from "hooks";

import translations from "./translations/en.json";

import * as styles from "./MobileSearch.module.scss";

interface MobileSearchProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const MobileSearch = ({ isOpen, toggleIsOpen }: MobileSearchProps) => {
  const [searchValue, setSearchValue] = useState("");

  const search = useSearchUrl();
  const searchCallback = useCallback(() => {
    toggleIsOpen();
    search(searchValue);
  }, [searchValue]);

  const searchAction = {
    onClick: () => searchCallback(),
    primary: true,
    content: translations.search.cta,
  };

  return (
    <Collapsible open={isOpen}>
      <div className={styles.MobileSearch}>
        <Stack vertical>
          <TextField
            value={searchValue}
            onChange={setSearchValue}
            placeholder={translations.search.placeholder}
            action={searchAction}
            type="search"
          />
          <div className={styles.CancelWrapper}>
            <TextLink onClick={toggleIsOpen}>
              {translations.search.cancel}
            </TextLink>
          </div>
        </Stack>
      </div>
    </Collapsible>
  );
};

export default MobileSearch;
