import { useCallback, useState } from 'react';

export const useModal = () => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = useCallback((content) => {
    setOpen(true);
    setModalContent(content);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    setModalContent(null);
  }, []);

  return {
    open,
    modalContent,
    openModal,
    closeModal,
  };
};
