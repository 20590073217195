import React from 'react';
import { CartProductType } from 'hooks';

import { ProductListRow } from './components';

import * as styles from './ProductList.module.scss';
import { Stack } from 'components';
interface ProductListProps {
  cart: CartProductType[];
}

const ProductList = ({ cart }: ProductListProps) => {
  return (
    <div className={styles.ProductList}>
      <Stack vertical spacing='extraLoose'>
        {cart.map((product) => (
          <ProductListRow key={product.productId} product={product} />
        ))}
      </Stack>
    </div>
  );
};

export default ProductList;
