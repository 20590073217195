import React, { useState } from "react";
import { Body, Button, Heading, Stack, TextField } from "components";
import { useSearchUrl } from "hooks";

import translations from "./translations/en.json";

import * as styles from "./Home.module.scss";
import { CataloguesSection, DivisionsSection } from "./components";

export const Home = () => {
  const [searchValue, setSearchValue] = useState("");

  const search = useSearchUrl();

  const searchAction = {
    onClick: () => search(searchValue),
    primary: true,
    content: translations.search.cta,
  };

  return (
    <div className={styles.HomeWrapper}>
      <div className={styles.SplashWrapper}>
        <div className={styles.Splash}>
          <div className={styles.SplashContentWrapper}>
            <Stack vertical spacing="extraLoose">
              <h1 className={styles.DisplayText}>{translations.displayText}</h1>
              <TextField
                value={searchValue}
                onChange={setSearchValue}
                placeholder={translations.search.placeholder}
                action={searchAction}
                type="search"
              />
            </Stack>
          </div>
        </div>
      </div>
      <DivisionsSection />
      <CataloguesSection />
    </div>
  );
};
