import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUpdateSearchParams } from "hooks";
import { ProductFormatEnum } from "types";

const searchParamKey = "format";

export const useFormatState = () => {
  const productFormatEnumKeys = Object.keys(ProductFormatEnum);
  const { search } = useLocation();
  const [filteredFormats, setFilteredFormats] = useState<boolean[]>(
    new Array(productFormatEnumKeys.length).fill(false)
  );
  const { updateSearchParams } = useUpdateSearchParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const formatParam = JSON.parse(searchParams.get("formats"));

    if (!formatParam) {
      return;
    }
    const newFilteredFormatsCheckState = new Array(
      productFormatEnumKeys.length
    ).fill(false);

    productFormatEnumKeys.forEach((key, index) => {
      if (formatParam.includes(key)) {
        newFilteredFormatsCheckState[index] = true;
      }
    });

    setFilteredFormats([...newFilteredFormatsCheckState]);
  }, [search, setFilteredFormats]);

  const filteredFormatsCallback = useCallback(
    (format: ProductFormatEnum) => {
      const indexOfFormat = productFormatEnumKeys.indexOf(format);
      filteredFormats[indexOfFormat] = !filteredFormats[indexOfFormat];
      const refFilteredFormats = [...filteredFormats];
      setFilteredFormats(refFilteredFormats);

      let args = [];
      refFilteredFormats.forEach(
        (checked, i) =>
          checked && args.push(ProductFormatEnum[productFormatEnumKeys[i]])
      );

      updateSearchParams({ name: searchParamKey, value: args });
    },
    [filteredFormats]
  );

  return { filteredFormats, filteredFormatsCallback };
};
