import React from 'react';
import { appendClassName, classNames } from 'utilities';
import * as styles from './TextStyle.module.scss';

interface TextStyleProps {
  variation?:
    | 'subdued'
    | 'brand'
    | 'critical'
    | 'success'
    | 'warning'
    | 'light'
    | 'dark'
    | 'darker'
    | 'darkest'
    | 'strong'
    | 'weak'
    | 'uppercase';
}

const TextStyle: React.FunctionComponent<TextStyleProps> = ({
  variation,
  children,
}) => {
  return (
    <span
      className={classNames(
        styles.TextStyle,
        styles[appendClassName('variation', variation)]
      )}
    >
      {children}
    </span>
  );
};

export default TextStyle;
