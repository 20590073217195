import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Stack,
  Heading,
  Hairline,
  Body,
  TextLink,
  TextStyle,
} from "components";
import { Routes } from "foundation";

import * as styles from "./CartEmptyState.module.scss";

import translations from "./translations/en.json";

const CartEmptyState = () => {
  return (
    <div className={styles.CartEmptyState}>
      <Stack vertical spacing="loose">
        <Stack vertical>
          <Heading size="extraSmall">{translations.noItemsInCart}</Heading>
          <TextLink to={Routes.home}>
            <TextStyle variation="strong">
              {translations.continueShopping}
            </TextStyle>
          </TextLink>
        </Stack>
        <Stack vertical>
          <Heading size="extraSmall">{translations.help.title}</Heading>
          <TextLink to={`mailto:${translations.help.email}`} external>
            <TextStyle variation="strong">
              {translations.help.contactUs}
            </TextStyle>
          </TextLink>
        </Stack>
      </Stack>
    </div>
  );
};

export default CartEmptyState;
