import React from 'react';

function createMarkup(html) {
  return { __html: html };
}

const DangerouslySetInnerHTML: React.FunctionComponent = ({ children }) => {
  return <span dangerouslySetInnerHTML={createMarkup(children)} />;
};

export default DangerouslySetInnerHTML;
