import { gql } from '@apollo/client';

export const CART_PRODUCT_SEARCH = gql`
  query cartProductSearch($ean: String!) {
    productByEan(ean: $ean) {
      author
      # collectionId
      # collectionNames
      format
      imageUrl
      isbn
      price
      publicationDate
      subtitle
      title
    }
  }
`;
