import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Routes } from "foundation";

import { ProductDetails } from "./components";

export const Products = () => {
  return (
    <Switch>
      <Route path={Routes.productById}>
        <ProductDetails />
      </Route>
      <Route path={Routes.products}>
        <h3>Product index</h3>
        <Redirect to={`${Routes.searchBase}/_`} />
      </Route>
    </Switch>
  );
};
