import React from "react";

import {
  Body,
  Collapsible,
  Hairline,
  Stack,
  TextContainer,
  TextLink,
  TextStyle,
} from "components";

import * as styles from "./DivisionsCollapsibleSection.module.scss";
import translations from "./translations/en.json";
import { useIsSmallScreen } from "hooks";

interface DivisionsCollapsibleSectionProps {
  open: boolean;
  toggleOpen: () => void;
}

export const DivisionsCollapsibleSection = ({
  open,
  toggleOpen,
}: DivisionsCollapsibleSectionProps) => {
  const isSmallScreen = useIsSmallScreen();

  return (
    <Collapsible open={open}>
      <div className={styles.DivisionsSection}>
        <div className={styles.Division}>
          <Stack vertical distribution="leading">
            {/* <TextLink secondary onClick={(toggleOpen)}> */}
            <TextContainer>
              <Body>
                <TextStyle variation="strong">
                  {translations.library.title}
                </TextStyle>
              </Body>
              {!isSmallScreen && (
                <Body>{translations.library.description}</Body>
              )}
            </TextContainer>
            {/* </TextLink> */}
          </Stack>
        </div>

        <div className={styles.Division}>
          <Stack vertical distribution="leading">
            <TextLink
              secondary
              to="http://www.crosscaneducation.com/classroom.php"
              external
            >
              <TextContainer>
                <TextStyle variation="strong">
                  {translations.classroom.title}
                </TextStyle>
                {!isSmallScreen && (
                  <Body>{translations.classroom.description}</Body>
                )}
              </TextContainer>
            </TextLink>
          </Stack>
        </div>

        <div className={styles.Division}>
          <Stack vertical distribution="leading">
            <TextLink
              secondary
              to="http://www.crosscaneducation.com/digital.php"
              external
            >
              <TextContainer>
                <TextStyle variation="strong">
                  {translations.digital.title}
                </TextStyle>
                {!isSmallScreen && (
                  <Body>{translations.digital.description}</Body>
                )}
              </TextContainer>
            </TextLink>
          </Stack>
        </div>
      </div>
      <Hairline spacing="none" />
    </Collapsible>
  );
};
