function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.substring(1);
}

export function mapSnakeCaseToTitleCase(string: string) {
  if (!string) {
    return '';
  }
  const splitString = string.split('_');

  let referenceString = '';

  splitString.forEach((substring, index) => {
    if (index === 0) {
      referenceString = capitalizeFirstLetter(substring);
    } else if (substring.toLowerCase() === 's') {
      referenceString += "'s";
    } else {
      referenceString += ` ${capitalizeFirstLetter(substring)}`;
    }
  });

  return referenceString;
}
