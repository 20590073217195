import React from 'react';
import { Hairline, Heading, Select, Stack, TextField } from 'components';
import { ProvinceEnum } from 'types';
import { validateTextField, ValidateTextFieldEnum } from 'utilities';
import { useCart } from 'hooks';

import {
  CheckoutFormActions,
  CheckoutFormState,
  DispatchAction,
  TitleTypes,
} from '../../../../types';

import * as styles from './DeliverySection.module.scss';

import translations from './translations/en.json';

interface DeliverySectionProps {
  formState: CheckoutFormState;
  dispatch: (args: DispatchAction) => void;
}

const DeliverySection = ({ formState, dispatch }: DeliverySectionProps) => {
  const {
    province: { setProvince },
  } = useCart();

  const onChangeCallback = (type: CheckoutFormActions) => {
    return (value) =>
      dispatch({
        type,
        payload: value,
      });
  };

  const provinceCallback = (value) => {
    const formCallback = onChangeCallback(CheckoutFormActions.province);
    formCallback(value);
    setProvince(value);
  };

  const onValidateError = (type: CheckoutFormActions) => {
    return (value: boolean) => {
      if (!value) {
        const indexOfError = formState.errors.indexOf(type);
        if (indexOfError < 0) {
          dispatch({
            type: CheckoutFormActions.errors,
            payload: [...formState.errors, type],
          });
        }
      } else {
        const indexOfError = formState.errors.indexOf(type);
        if (indexOfError >= 0) {
          formState.errors.splice(indexOfError, 1);
          dispatch({
            type: CheckoutFormActions.errors,
            payload: [...formState.errors],
          });
        }
      }
    };
  };

  const provinceOptions = Object.keys(ProvinceEnum).map((key) => {
    return {
      content: ProvinceEnum[key],
      value: ProvinceEnum[key],
    };
  });

  const titleOptions = Object.keys(TitleTypes).map((key) => {
    return {
      content: TitleTypes[key],
      value: TitleTypes[key],
    };
  });

  return (
    <Stack vertical spacing='baseLoose'>
      <Heading size='small'>{translations.title}</Heading>
      <Stack.Item spacing='baseLoose' fullWidth>
        <Stack vertical spacing='extraTight'>
          <Stack distribution='equalSpacing' spacing='extraTight'>
            <Stack.Item fill spacing='extraTight'>
              <TextField
                value={formState.firstName}
                onChange={onChangeCallback(CheckoutFormActions.firstName)}
                placeholder={translations.firstNamePlaceholder}
                type='text'
                required
              />
            </Stack.Item>
            <Stack.Item fill spacing='extraTight'>
              <TextField
                value={formState.lastName}
                onChange={onChangeCallback(CheckoutFormActions.lastName)}
                placeholder={translations.lastNamePlaceholder}
                type='text'
                required
              />
            </Stack.Item>
          </Stack>
          <Select
            value={formState.title}
            options={titleOptions}
            onChange={onChangeCallback(CheckoutFormActions.title)}
            placeholder={translations.titlePlaceholder}
            fullWidth
            required
          />
          <TextField
            value={formState.institutionName}
            onChange={onChangeCallback(CheckoutFormActions.institutionName)}
            placeholder={translations.institutionPlaceholder}
            type='text'
            required
          />
          <TextField
            value={formState.phoneNumber}
            onChange={onChangeCallback(CheckoutFormActions.phoneNumber)}
            placeholder={translations.phoneNumberPlaceholder}
            validate={validateTextField(ValidateTextFieldEnum.phoneNumber)}
            onValidateError={onValidateError(ValidateTextFieldEnum.phoneNumber)}
            type='tel'
            required
          />
        </Stack>
      </Stack.Item>
      <Stack.Item spacing='baseLoose' fullWidth>
        <Stack vertical spacing='extraTight'>
          <TextField
            value={formState.address}
            onChange={onChangeCallback(CheckoutFormActions.address)}
            placeholder={translations.addressPlaceholder}
            type='text'
            required
          />
          <TextField
            value={formState.address2}
            onChange={onChangeCallback(CheckoutFormActions.address2)}
            placeholder={translations.address2Placeholder}
            type='text'
          />
          <TextField
            value={formState.city}
            onChange={onChangeCallback(CheckoutFormActions.city)}
            placeholder={translations.cityPlaceholder}
            type='text'
            required
          />
          <div className={styles.TwoColumn}>
            <Select
              value={formState.province}
              options={provinceOptions}
              onChange={provinceCallback}
              placeholder={translations.provincePlaceholder}
              fullWidth
              required
            />
            <TextField
              value={formState.postalCode}
              onChange={onChangeCallback(CheckoutFormActions.postalCode)}
              placeholder={translations.postalCodePlaceholder}
              validate={validateTextField(ValidateTextFieldEnum.postalCode)}
              onValidateError={onValidateError(
                ValidateTextFieldEnum.postalCode
              )}
              type='text'
              required
            />
          </div>
        </Stack>
      </Stack.Item>
      <Hairline spacing='none' />
    </Stack>
  );
};

export default DeliverySection;
