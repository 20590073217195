import React, { useState } from "react";
import { Collapsible, Icon, Stack, TextLink, TextStyle } from "components";
import { ChevronDown as ChevronDownIcon } from "assets/icons";
import * as styles from "./SearchFilterSection.module.scss";
import { classNames } from "utilities";

interface SearchFilterSectionProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  title: string;
}

const SearchFilterSection: React.FunctionComponent<
  SearchFilterSectionProps
> = ({ isOpen, children, toggleIsOpen, title }) => {
  const chevronClassNames = classNames(isOpen && styles.Rotated);

  return (
    <div className={styles.SearchFilterSection}>
      <TextLink onClick={toggleIsOpen} secondary>
        <div className={styles.FilterToggleWrapper}>
          <Stack alignment="center">
            <div className={chevronClassNames}>
              <Icon source={ChevronDownIcon} fill="base" />
            </div>
            <TextStyle variation="strong">{title}</TextStyle>
          </Stack>
        </div>
      </TextLink>
      <Collapsible open={isOpen}>
        <div className={styles.Filters}>{children}</div>
      </Collapsible>
    </div>
  );
};

export default SearchFilterSection;
