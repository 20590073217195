import React from 'react';

import { ProductFormatEnum } from 'types';
import {
  SocialMediaBar,
  TransactionPanel,
  TransactionPanelProps,
} from './components';
import * as styles from './TransactionSection.module.scss';

export interface TransactionSectionProps {
  transactionDetails: TransactionPanelProps;
}

const TransactionSection = ({
  transactionDetails,
}: TransactionSectionProps) => {
  return (
    <div className={styles.TransactionSection}>
      <TransactionPanel {...transactionDetails} />
      <SocialMediaBar />
    </div>
  );
};

export default TransactionSection;
