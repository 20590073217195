import React from "react";
import {
  Body,
  Button,
  Checkbox,
  Hairline,
  Heading,
  Select,
  Stack,
  TextLink,
  TextStyle,
} from "components";
import { ProductOrderBy } from "types";

import * as styles from "./ResultsOverviewSection.module.scss";

import { useOrderBy } from "./hooks";
import translations from "./translations/en.json";

interface ResultsOverviewSectionProps {
  searchTerm: string;
  currentCount: number;
  totalCount: number;
}

const ResultsOverviewSection = ({
  searchTerm,
  currentCount,
  totalCount,
}: ResultsOverviewSectionProps) => {
  const orderByOptions = [
    {
      content: translations.sortBy.options.priceLowestToHighest,
      value: ProductOrderBy.price_ASC,
    },
    {
      content: translations.sortBy.options.priceHighestToLowest,
      value: ProductOrderBy.price_DESC,
    },
  ];

  const { orderBy, orderByCallback } = useOrderBy();

  return (
    <Stack vertical spacing="baseLoose">
      <Stack distribution="equalSpacing">
        <Stack.Item fill>
          <Stack vertical spacing="tight">
            <Heading>
              {translations.searchResultsFor} {searchTerm}
            </Heading>
            <Body>{`${
              totalCount === 0 ? 0 : 1
            } to ${currentCount} of ${Math.max(
              totalCount,
              currentCount
            )} results`}</Body>
          </Stack>
        </Stack.Item>
        <Select
          value={orderBy}
          // label={translations.sortBy.title}
          placeholder={translations.sortBy.placeholder}
          options={orderByOptions}
          onChange={orderByCallback}
        />
      </Stack>

      {/* <Stack vertical spacing='baseTight'>
        <Stack distribution='equalSpacing'>
          <Button onClick={() => {}}>
            All content | Canadian content only
          </Button>

          <Button onClick={() => {}}>Handle sort order</Button>
        </Stack>
        <Stack spacing='tight'>
          <Checkbox
            label={translations.hideUnavailableItems}
            checked={false}
            toggleChecked={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
          <Checkbox
            label={translations.hideUnreleasedItems}
            checked={false}
            toggleChecked={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
        </Stack>
      </Stack> */}

      <Hairline spacing="none" />
    </Stack>
  );
};

export default ResultsOverviewSection;
