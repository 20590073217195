import React from "react";
import { Label, Stack } from "components";

import * as styles from "./Checkbox.module.scss";

interface CheckboxProps {
  label: string;
  checked: boolean;
  toggleChecked: () => void;
}

const Checkbox = ({ label, checked, toggleChecked }: CheckboxProps) => {
  return (
    <Stack spacing="extraExtraTight">
      <div className={styles.CheckboxWrapper}>
        <input
          type="checkbox"
          className={styles.Checkbox}
          id={label}
          checked={checked}
          onChange={toggleChecked}
        />
        <span className={styles.Checkmark} />
      </div>
      <div className={styles.Label}>
        <Label labelFor={label} secondary>
          {label}
        </Label>
      </div>
    </Stack>
  );
};

export default Checkbox;
