import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Portal } from "components";

import { useModal } from "hooks";
import { PortalRootEnum } from "components/Portal/types";

import * as styles from "./ModalContext.module.scss";

interface State {
  openModal: (content: JSX.Element) => void;
  closeModal: () => void;
}

const initialState: State = {
  openModal: (_content) => {},
  closeModal: () => {},
};

const ModalContext = createContext<State>(initialState);

export const ModalProvider: React.FunctionComponent = ({ children }) => {
  const { open, modalContent, openModal, closeModal } = useModal();
  const contentWrapper = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [persistedPosition, setPersistedPosition] = useState(0);
  // this state is needed to help manage re-render flickering
  const [isOpen, setIsOpen] = useState(open);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (open) {
      setPersistedPosition(scrollPosition);
      contentWrapper.current.scrollTop = scrollPosition;
      setIsOpen(open);
    } else {
      setIsOpen(open);
      window.requestAnimationFrame(() => {
        window.scrollTo({
          top: persistedPosition,
          left: 0,
          behavior: "auto",
        });
      });
    }
  }, [open, setPersistedPosition, persistedPosition]);

  const value = {
    openModal: (content) => openModal(content),
    closeModal: () => closeModal(),
  };

  return (
    <ModalContext.Provider value={value}>
      <div
        ref={contentWrapper}
        className={isOpen ? styles.ModalActive : undefined}
      >
        <Portal type={PortalRootEnum.Modal}>{modalContent}</Portal>
        {children}
      </div>
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
