import React, { useState } from "react";

import { Stack, TextLink, TextStyle } from "components";

import { DivisionsCollapsibleSection } from "./components";

import * as styles from "./SubHeader.module.scss";
import { useIsSmallScreen } from "hooks";
import { classNames } from "utilities";

interface SubHeaderProps {
  mobileMenuOpen: boolean;
}

export const SubHeader = ({ mobileMenuOpen }: SubHeaderProps) => {
  const [collapsibleOpen, setCollapsibleOpen] = useState(false);
  const isSmallScreen = useIsSmallScreen();

  const toggleDivisions = () => {
    setCollapsibleOpen((collapsibleOpen) => !collapsibleOpen);
  };

  const subheaderClassNames = classNames(
    styles.SubHeader,
    mobileMenuOpen && styles.Open
  );

  return (
    <>
      <div className={subheaderClassNames}>
        <Stack spacing="baseLoose" vertical={isSmallScreen}>
          <div>
            <TextLink secondary onClick={toggleDivisions}>
              <TextStyle variation="strong">Divisions</TextStyle>
            </TextLink>
            {isSmallScreen ? (
              <DivisionsCollapsibleSection
                open={collapsibleOpen}
                toggleOpen={toggleDivisions}
              />
            ) : null}
          </div>
          <TextLink
            secondary
            to="http://www.crosscaneducation.com/showroom.php"
            external
          >
            <TextStyle variation="strong">Virtual Showroom</TextStyle>
          </TextLink>
        </Stack>
      </div>
      {!isSmallScreen && (
        <DivisionsCollapsibleSection
          open={collapsibleOpen}
          toggleOpen={toggleDivisions}
        />
      )}
    </>
  );
};
