import React from 'react';
import { classNames } from 'utilities';
import { Label, Stack } from 'components';

import * as styles from './Select.module.scss';

interface Option {
  content: string;
  value: string;
}

interface SelectProps {
  label?: string;
  value: string;
  options: Option[];
  onChange: (value: string) => void;
  fullWidth?: boolean;
  placeholder?: string;
  required?: boolean;
}

const Select = ({
  label,
  value,
  options,
  onChange,
  fullWidth = false,
  placeholder,
  required = false,
}: SelectProps) => {
  const handleChange = (event) => {
    const value = event.target.value;
    onChange(value);
  };

  const selectWrapperClassNames = classNames(
    styles.SelectWrapper,
    fullWidth && styles.FullWidth
  );

  const selectClassNames = classNames(
    styles.Select,
    fullWidth && styles.FullWidth
  );

  return (
    <Stack vertical>
      {label && <Label labelFor={label}>{label}</Label>}
      <div className={selectWrapperClassNames}>
        <select
          id={label}
          className={selectClassNames}
          value={value}
          onChange={handleChange}
          required={required}
        >
          {placeholder && (
            <option value={''} key={`${placeholder}-option`} disabled hidden>
              {placeholder}
            </option>
          )}
          {options.map(({ content, value }) => (
            <option value={value} key={`${label}-${value}-option`}>
              {content}
            </option>
          ))}
        </select>
      </div>
    </Stack>
  );
};

export default Select;
