import React from 'react';
import { useCart } from 'hooks';
import { useHistory } from 'react-router-dom';
import { Routes } from 'foundation';

import { TransactionSection } from '../TransactionSection';

import {
  CartActions,
  CartEmptyState,
  ProductList,
  TitleSection,
} from './components';

import * as styles from './CartList.module.scss';

const CartList = () => {
  const { cart, totalNumberOfProducts } = useCart();

  let history = useHistory();

  const goToCheckoutCallback = () => {
    history.push(Routes.checkout);
  };

  const cartIsEmpty = cart.length <= 0;

  return (
    <div className={styles.CartListWrapper}>
      <div className={styles.CartList}>
        <TitleSection />
        {!cartIsEmpty && <CartActions cart={cart} />}
        {cartIsEmpty && <CartEmptyState />}
        {!cartIsEmpty && <ProductList cart={cart} />}
        {!cartIsEmpty && (
          <TransactionSection
            cart={cart}
            totalNumberOfProducts={totalNumberOfProducts}
            callback={goToCheckoutCallback}
          />
        )}
      </div>
    </div>
  );
};

export default CartList;
