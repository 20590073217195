import React from 'react';
import { useCartContext } from 'context';
import { ShoppingCart as ShoppingCartIcon } from 'assets/icons';
import { Body, Icon, Stack, TextLink, TextStyle } from 'components';
import { Routes } from 'foundation';

interface HeaderProps {}

export const HeaderMenu: React.FunctionComponent<HeaderProps> = () => {
  const { totalNumberOfProducts } = useCartContext();

  return (
    <Stack spacing='baseTight'>
      {/* <TextLink secondary onClick={() => {}}>
        <Stack spacing='extraExtraTight'>
          <Icon source={CloseIcon} />
          <Body size='small'>Login</Body>
        </Stack>
      </TextLink>

      <TextLink secondary onClick={() => {}}>
        <Stack spacing='extraExtraTight'>
          <Icon source={CloseIcon} />
          <Body size='small'>My lists</Body>
        </Stack>
      </TextLink> */}

      <TextLink secondary to={Routes.cart}>
        <Stack spacing='extraExtraTight'>
          <Icon source={ShoppingCartIcon} />
          <Body size='small'>
            <TextStyle variation='strong'>{totalNumberOfProducts}</TextStyle>
          </Body>
        </Stack>
      </TextLink>
    </Stack>
  );
};
