import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProductOrderBy } from "types";
import { useUpdateSearchParams } from "hooks";

const searchParamKey = "orderBy";

export const useOrderBy = () => {
  const { search } = useLocation();
  const [orderBy, setOrderBy] = useState<ProductOrderBy | "">("");
  const { updateSearchParams } = useUpdateSearchParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const json = function (raw) {
      try {
        return JSON.parse(raw);
      } catch (e) {
        return [];
      }
    };
    let orderByParam = json(searchParams.get(searchParamKey));

    if (!orderByParam) {
      return;
    }

    setOrderBy(ProductOrderBy[orderByParam]);
  }, [search, setOrderBy]);

  const orderByCallback = useCallback(
    (orderByArg: ProductOrderBy) => {
      updateSearchParams({ name: searchParamKey, value: orderByArg });
    },
    [orderBy]
  );

  return { orderBy, orderByCallback };
};
