import { useHistory } from "react-router-dom";
import {
  ProductCollectionEnum,
  ProductFormatEnum,
  ProductOrderBy,
} from "types";

interface PriceRange {
  min: number | undefined;
  max: number | undefined;
}

interface SearchParamObject {
  // TODO: convert to enum to be consumed by consumers
  name: "format" | "collection" | "orderBy" | "price";
  value:
    | ProductCollectionEnum[]
    | ProductFormatEnum[]
    | ProductOrderBy
    | PriceRange
    | "delete";
}

export const useUpdateSearchParams = () => {
  const history = useHistory();

  const updateSearchParams = (paramToBeUpdated: SearchParamObject) => {
    let searchParams = new URLSearchParams(history.location.search);
    const { name, value } = paramToBeUpdated;

    if (value === "delete") {
      searchParams.delete(name);
    } else if (name === "format") {
      //error using 'format' in url
      searchParams.set("formats", JSON.stringify(value));
    } else if (name === "collection") {
      searchParams.set(name, JSON.stringify(value));
    } else if (name === "price") {
      searchParams.set(name, JSON.stringify(value));
    }

    history.replace(`${history.location.pathname}?${searchParams.toString()}`);
  };

  return { updateSearchParams };
};
