//https://www.30secondsofcode.org/react/s/use-media-query
import React, { useEffect, useState } from 'react';

export const useMediaQuery = (query) => {
  if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined')
    return false;

  const mediaQuery = window.matchMedia(query);
  const [match, setMatch] = useState(!!mediaQuery.matches);

  useEffect(() => {
    const handler = () => setMatch(!!mediaQuery.matches);
    mediaQuery.addEventListener('change', handler);
    return () => mediaQuery.removeEventListener('change', handler);
  }, []);

  return match;
};

export const mediaQuery = {
  mobile: '(max-width: 480px)',
  tablet: '(max-width: 992px)',
};

export const useIsSmallScreen = () => useMediaQuery(mediaQuery.mobile);
export const useIsMediumScreen = () => useMediaQuery(mediaQuery.tablet);
