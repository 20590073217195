import React from 'react';
import * as styles from './QuickLinks.module.scss';

interface QuickLinksProps {

};

const QuickLinks: React.FunctionComponent<QuickLinksProps> = ({
  children
}) => {
  return (
    <div className={styles.QuickLinks}>
      {children}
    </div>
  );
}

export default QuickLinks;