import React from 'react';
import { appendClassName, classNames } from 'utilities';
import * as styles from './TextContainer.module.scss';

interface TextContainerProps {
  align?: 'left' | 'center' | 'right';
}

const TextContainer: React.FunctionComponent<TextContainerProps> = ({
  children,
  align = 'left',
}) => {
  const textContainerClassNames = classNames(
    styles.TextContainer,
    styles[appendClassName('align', align)]
  );
  return <div className={textContainerClassNames}>{children}</div>;
};

export default TextContainer;
