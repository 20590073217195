import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation createOrder(
    $products: [OrderProductsAttributes!]!
    $shippingAddress: ShippingAddressAttributes!
    $customerProfile: OrderCustomerProfileAttributes!
    $discount: String
  ) {
    addOrder(
      products: $products
      shippingAddress: $shippingAddress
      customerProfile: $customerProfile
      discount: $discount
    ) {
      errors
      order {
        customer
        id
      }
    }
  }
`;
