import React, { useMemo } from 'react';

import { PortalRootEnum } from '../types';

export function usePortalManager(portal: PortalRootEnum): HTMLElement {
  const portals = useMemo(() => {
    const portalRoots = {};
    for (const property in PortalRootEnum) {
      portalRoots[PortalRootEnum[property]] = document.getElementById(
        `${PortalRootEnum[property]}-root`
      );
    }
    return portalRoots;
  }, []);

  return portals[portal];
}
