import React, { Children } from 'react';
import { SpacingType, StackItem } from './components';
import { appendClassName, classNames, wrapWithComponent } from 'utilities';

import * as styles from './Stack.module.scss';

type DistributionType = 'leading' | 'trailing' | 'center' | 'equalSpacing';
type AlignmentType = 'leading' | 'trailing' | 'center' | 'fill' | 'baseline';

interface StackProps {
  children: React.ReactNode;
  spacing?: SpacingType;
  alignment?: AlignmentType;
  distribution?: DistributionType;
  vertical?: boolean;
  wrap?: boolean;
}

const Stack = ({
  children,
  spacing = 'extraTight',
  alignment = 'leading',
  distribution = 'leading',
  vertical = false,
  wrap = false,
}: StackProps) => {
  const stackClassNames = classNames(
    styles.Stack,
    styles[appendClassName('spacing', spacing)],
    styles[appendClassName('alignment', alignment)],
    styles[appendClassName('distribution', distribution)],
    vertical && styles.Vertical,
    !wrap && styles.NoWrap
  );

  const wrappedChildren = Children.toArray(children).map((child, index) => {
    const props = { key: index, spacing, fullWidth: vertical };
    return wrapWithComponent(child, StackItem, props);
  });

  return <div className={stackClassNames}>{wrappedChildren}</div>;
};

Stack.Item = StackItem;

export default Stack;
