import React from 'react';
// import { ApolloProvider } from 'react-apollo';
import { ApolloProvider } from '@apollo/client';
import { createCache, createClient } from '../../../utils/apollo';

export const ConnectionProvider: React.FunctionComponent = ({ children }) => {
  return (
    <ApolloProvider client={createClient(createCache())}>
      {children}
    </ApolloProvider>
  );
};
