import React from 'react';
import { classNames } from 'utilities';

import * as styles from './Body.module.scss';

interface BodyProps {
  size?: 'default' | 'small';
  textDecoration?: 'none' | 'strikeThrough';
}

const Body: React.FunctionComponent<BodyProps> = ({
  size = 'default',
  textDecoration = 'none',
  children,
}) => {
  const bodyClassNames = classNames(
    styles.Body,
    size === 'small' ? styles.BodySmall : styles.BodyMedium,
    textDecoration === 'strikeThrough' && styles.StrikeThrough
  );

  return <p className={bodyClassNames}>{children}</p>;
};

export default Body;
