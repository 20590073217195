import React from 'react';
import { useParams } from 'react-router-dom';
import { Banner, Body } from 'components';

import { useProductDetails } from './hooks';
import { DetailsSection, TopSection } from './components';

import translations from './translations/en.json';

import * as styles from './ProductDetails.module.scss';

const ProductDetails = () => {
  const { id } = useParams();
  const { productDetails, loading, error } = useProductDetails(id);

  if (loading) {
    return <p>null</p>;
  }

  if (!productDetails || error) {
    return (
      <div className={styles.ProductDetails}>
        <div className={styles.ProductDetailsWrapper}>
          <div className={styles.BannerWrapper}>
            <Banner critical>
              <Body size='small'>
                {translations.error.prepend}
                {id}. {translations.error.append}
              </Body>
            </Banner>
          </div>
        </div>
      </div>
    );
  }

  const { overview, transaction, metaData } = productDetails;

  return (
    <div className={styles.ProductDetails}>
      <TopSection overviewDetails={overview} transactionDetails={transaction} />
      <DetailsSection metaData={metaData} />
      {/* <OverviewSection product={productDetails?.overview} />
      <TransactionSection transactionDetails={productDetails?.transaction} /> */}
    </div>
  );
};

export default ProductDetails;
