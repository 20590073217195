import React from "react";
import { Switch, Route } from "react-router-dom";
import { Routes } from "foundation";

import { CataloguesByCategory } from "./components";

export const Catalogues = () => {
  return (
    <Switch>
      <Route path={Routes.catalogueByCategory}>
        <CataloguesByCategory />
      </Route>
    </Switch>
  );
};
